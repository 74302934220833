import React from 'react'
import { List, useRecordContext, FunctionField } from 'react-admin'
import Tooltip from '@mui/material/Tooltip'
import { LinearProgress } from '@mui/material'
import { RequestServiceFilters } from '../RequestServiceFilters'
import { RequestServiceDatagrid } from '../RequestServiceDatagrid'

export const RequestFlowServicesList: React.FC<{
  displayFilters?: boolean
  requestId?: string
}> = (props) => {
  const record = useRecordContext()

  const requestId = props.requestId ?? record.id

  return (
    <List
      title=" "
      {...props}
      sort={{ field: 'name', order: 'asc' }}
      filter={{ requestId }}
      actions={false}
      empty={false}
      resource="requestServices"
      storeKey={`requestServices.${requestId}`}
      filters={props.displayFilters ? <RequestServiceFilters /> : undefined}
      disableSyncWithLocation
    >
      <FunctionField
        render={(record: { sites?: { state: string }[] }) => {
          let progressPercentage = 0
          let confirmedSites = 0
          let totalSites = 0

          if (record.sites) {
            totalSites = record.sites.length

            confirmedSites = record.sites.filter(
              (site) => site.state === 'complete'
            ).length

            progressPercentage = (confirmedSites / totalSites) * 100
          }

          return (
            <Tooltip
              title={`${confirmedSites} of ${totalSites} sites completed`}
              followCursor
            >
              <LinearProgress
                value={progressPercentage}
                variant="determinate"
                color="info"
                sx={{
                  margin: 0,
                  width: '100%',
                  borderRadius: '4px 4px 0 0',
                }}
              />
            </Tooltip>
          )
        }}
      />
      <RequestServiceDatagrid requestId={props.requestId} />
    </List>
  )
}

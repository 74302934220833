import React from 'react'
import {
  ChipField,
  ListBase,
  TextField,
  FunctionField,
  useRecordContext,
  ArrayField,
  SingleFieldList,
} from 'react-admin'
import { LabelledDatagrid } from '../../atoms/LabelledDatagrid'
import { CurrencyField } from '../../atoms/CurrencyField'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { ProductDetailsExpand } from '../../organisms/ProductDetailsExpand'
import { Tooltip } from '@mui/material'

/**
 * This component is used to generate a preview of the pricebook's contents
 * @example
 * return <PriceBookPreviewer />
 */
export const PriceBookPreviewer: React.FC = () => {
  const record = useRecordContext()

  return (
    <ListBase
      resource={`priceBooks/${record.id}/preview`}
      disableSyncWithLocation
    >
      <LabelledDatagrid
        title=" Preview"
        rowClick="expand"
        expand={<ProductDetailsExpand />}
        optimized
        bulkActionButtons={false}
      >
        <TextField source="code" />
        <ReferenceChip
          referenceSource={'vendorId'}
          tooltipSource="name"
          source="vendorId"
          chipSource="name"
          label={'Vendor'}
          reference="vendors"
          emptyText={'N/A'}
          removeDoubleLabel
          link={'show'}
        />
        <FunctionField
          label="Coverage zone"
          render={(record: any) => (
            <Tooltip title={record.coverageZone?.description}>
              <strong>
                <ChipField
                  size={'small'}
                  source="coverageZone.name"
                  fullWidth
                  label="Coverage Zone"
                />
              </strong>
            </Tooltip>
          )}
        />
        <ArrayField label="LMPs" source="lastMileProviders">
          <SingleFieldList linkType={false}>
            <FunctionField
              render={(record: any) => (
                <Tooltip title={!record.id ? 'Not mapped yet' : record.name}>
                  <strong>
                    {record.mapped ? (
                      <ChipField
                        size="small"
                        color="success"
                        source="mapped.name"
                      />
                    ) : (
                      <ChipField size="small" color="warning" source="name" />
                    )}
                  </strong>
                </Tooltip>
              )}
            />
          </SingleFieldList>
        </ArrayField>
        <ArrayField label="ISPs" source="internetServiceProviders">
          <SingleFieldList linkType={false}>
            <FunctionField
              render={(record: any) => (
                <Tooltip title={!record.id ? 'Not mapped yet' : record.name}>
                  <strong>
                    {record.mapped ? (
                      <ChipField
                        size="small"
                        color="success"
                        source="mapped.name"
                      />
                    ) : (
                      <ChipField size="small" color="warning" source="name" />
                    )}
                  </strong>
                </Tooltip>
              )}
            />
          </SingleFieldList>
        </ArrayField>
        <CurrencyField
          currency={'purchaseCurrency'}
          source="purchaseNrc"
          label={'Purchase NRC'}
        />
        <CurrencyField
          currency={'purchaseCurrency'}
          source="purchaseMrc"
          label={'Purchase MRC'}
        />
      </LabelledDatagrid>
    </ListBase>
  )
}

import React, { useEffect, useState } from 'react'
import {
  useRecordContext,
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  AutocompleteInput,
  ReferenceInput,
  SimpleForm,
  useDataProvider,
  useNotify,
  FormDataConsumer,
  Toolbar,
  SaveButton,
  useRefresh,
} from 'react-admin'
import { Box } from '@mui/material'

/**
 * This component is used to map values between a price book and existing values in the database
 * @example
 * return <PriceBookMapper />
 */
export const PriceBookMapper: React.FC = () => {
  const record = useRecordContext()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [mapping, setMapping] = useState<any>()

  useEffect(() => {
    setIsLoading(true)
    dataProvider
      .customRequest('priceBooks', `${record.id}/mapping`, { method: 'GET' })
      .then((data: any) => {
        setMapping(data.json?.data)
        setIsLoading(false)
      })
      .catch((error: any) => {
        setMapping({})
        notify(error.message, { type: 'error' })
        setIsLoading(false)
      })
  }, [record, dataProvider, notify])

  const handleSubmit = async (data: any) => {
    setIsSubmitting(true)
    try {
      await dataProvider.customRequest(
        'priceBooks',
        `${record.id}/mapping`,
        { method: 'PUT', data },
        { returnPromise: true }
      )
      refresh()
      notify('Mapping has been saved', { type: 'info' })
      setIsSubmitting(false)
    } catch (error: any) {
      notify(error.message || error, { type: 'warning' })
      setIsSubmitting(false)
    }
  }

  return (
    <SimpleForm
      onSubmit={handleSubmit}
      record={mapping}
      toolbar={
        <Toolbar>
          <SaveButton label="Save" alwaysEnable={!isLoading && !isSubmitting} />
        </Toolbar>
      }
    >
      <ArrayInput
        label="Internet Service Providers"
        source="internetServiceProviders"
        fullWidth
      >
        <SimpleFormIterator
          disableAdd
          disableClear
          disableRemove
          disableReordering
          fullWidth
        >
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => (
              <Box
                display={{ sm: 'block', md: 'flex' }}
                sx={{ width: '100%', mt: 1 }}
              >
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput
                    source={getSource?.('name') ?? 'name'}
                    label="Name on price book"
                    disabled
                    fullWidth
                    variant={'outlined'}
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <ReferenceInput
                    source={getSource?.('mappedId') ?? 'mappedId'}
                    reference="internetServiceProviders/list"
                  >
                    <AutocompleteInput
                      defaultValue={scopedFormData?.suggestion?.id}
                      optionText="name"
                      label="Name on database"
                      variant={'outlined'}
                    />
                  </ReferenceInput>
                </Box>
              </Box>
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput
        label="Last Mile Providers"
        source="lastMileProviders"
        fullWidth
      >
        <SimpleFormIterator
          disableAdd
          disableClear
          disableRemove
          disableReordering
          fullWidth
        >
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => (
              <Box
                display={{ sm: 'block', md: 'flex' }}
                sx={{ width: '100%', mt: 1 }}
              >
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput
                    source={getSource?.('name') ?? 'name'}
                    label="Name on price book"
                    disabled
                    fullWidth
                    variant={'outlined'}
                  />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <ReferenceInput
                    source={getSource?.('mappedId') ?? 'mappedId'}
                    reference="lastMileProviders/list"
                  >
                    <AutocompleteInput
                      defaultValue={scopedFormData?.suggestion?.id}
                      optionText="name"
                      label="Name on database"
                      variant={'outlined'}
                    />
                  </ReferenceInput>
                </Box>
              </Box>
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  )
}

import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Stack,
  Grid,
  CardContent,
  Card,
} from '@mui/material'
import ExpandAccordionIcon from '@mui/icons-material/ExpandMore'
import { ProductSearchQueryField } from '../../organisms/ProductSearchQueryField'
import { FunctionField, TextField } from 'react-admin'
import DoneIcon from '@mui/icons-material/CheckBoxOutlined'
import ToDoIcon from '@mui/icons-material/CheckBoxOutlineBlank'

/** Component that displays basic info about customer, preferences, requirements, add-on wishes - uses RequestService as its context */
export const RequestDetailsAccordion: React.FC = () => (
  <Accordion defaultExpanded disableGutters>
    <AccordionSummary expandIcon={<ExpandAccordionIcon color="info" />}>
      <Typography variant="h6" alignItems="center" display="flex" gap={1}>
        <FunctionField
          render={(record: { state: string }) =>
            record.state !== 'pending-search' ? (
              <DoneIcon sx={{ mr: 1 }} />
            ) : (
              <ToDoIcon sx={{ mr: 1 }} />
            )
          }
        />
        Request: Service Preferences
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <Stack spacing={1}>
            <Typography color="gray" variant="body2">
              Preferences
            </Typography>
            <ProductSearchQueryField label={false} source="preferences" />
          </Stack>
          <Stack spacing={1} my={2}>
            <Typography color="gray" variant="body2">
              Additional notes
            </Typography>
            <Card variant="outlined" sx={{ maxWidth: '90%' }}>
              <CardContent>
                <TextField source="additionalNotes" emptyText="N/A" />
              </CardContent>
            </Card>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Stack spacing={1}>
            <Typography color="gray" variant="body2">
              Requirements
            </Typography>
            <ProductSearchQueryField label={false} source="requirements" />
          </Stack>
          <Stack spacing={1} my={2}>
            <Typography color="gray" variant="body2">
              Diversity
            </Typography>
            <TextField source="diversity" emptyText="N/A" sx={{ pl: 2 }} />
          </Stack>
          <Stack spacing={1} my={2}>
            <Typography color="gray" variant="body2">
              Additional products &amp; configuration
            </Typography>
            <ProductSearchQueryField label={false} source="addons" />
          </Stack>
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>
)

import React from 'react'
import { List, ListProps } from 'react-admin'
import { RequestServiceFilters } from '../../molecules/RequestServiceFilters'
import { RequestServiceDatagrid } from '../../molecules/RequestServiceDatagrid'

/** Page to display a list of request services. */
export const RequestServiceList: React.FC<Partial<ListProps>> = (props) => {
  return (
    <List
      empty={false}
      filters={<RequestServiceFilters />}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      perPage={25}
      {...props}
    >
      <RequestServiceDatagrid />
    </List>
  )
}

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Typography,
} from '@mui/material'
import React from 'react'
import {
  SimpleForm,
  useRecordContext,
  useDataProvider,
  useRefresh,
  useNotify,
} from 'react-admin'
import { MarkdownField, MarkdownInput } from '@react-admin/ra-markdown'
import ExpandAccordionIcon from '@mui/icons-material/ExpandMore'
import DoneIcon from '@mui/icons-material/CheckBoxOutlined'
import ToDoIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { SalesNotesFormToolbar } from './SalesNotesFormToolbar.component'
import { ProductSalesReviewAccordionProps } from './ProductSalesReviewAccordion.types'

/** Component to allow sales to add/edit notes - uses RequestService as its context */
export const ProductSalesReviewAccordion: React.FC<
  ProductSalesReviewAccordionProps
> = (props) => {
  const record = useRecordContext(props)
  const refresh = useRefresh()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const [isLoading, setIsLoading] = React.useState(false)

  const handleSave = async ({ reviewNotes }: { reviewNotes?: string }) => {
    setIsLoading(true)

    try {
      const data = await dataProvider.customRequest(
        'requestServices',
        `${record.id}/salesReview`,
        {
          data: { reviewNotes },
          method: 'PUT',
        }
      )
      if (data?.json?.data?.id) {
        notify('Review notes have been saved')
      }
      refresh()
    } catch (error: any) {
      notify(error.message, { type: 'error' })
    }

    setIsLoading(false)
  }

  const handleConfirm = async () => {
    setIsLoading(true)

    try {
      const data = await dataProvider.customRequest(
        'requestServices',
        `${record.id}/salesReview`,
        {
          data: { action: 'confirm' },
          method: 'PUT',
        }
      )
      if (data?.json?.data?.id) {
        notify('Review has been confirmed')
      }
      refresh()
    } catch (error: any) {
      notify(error.message, { type: 'error' })
    }

    setIsLoading(false)
  }

  if (!record) return null

  return (
    <Accordion defaultExpanded disableGutters>
      <AccordionSummary expandIcon={<ExpandAccordionIcon color="info" />}>
        <Typography variant="h6" alignItems="center" display="flex" gap={1}>
          {record.isReviewConfirmed ? (
            <DoneIcon sx={{ mr: 1 }} />
          ) : (
            <ToDoIcon sx={{ mr: 1 }} />
          )}{' '}
          Sales: Initial Review
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {record.isReviewConfirmed ? (
          <Card variant="outlined">
            <CardContent>
              <MarkdownField
                source="reviewNotes"
                emptyText="N/A"
                label={false}
              />
            </CardContent>
          </Card>
        ) : (
          <SimpleForm
            onSubmit={handleSave}
            toolbar={
              <SalesNotesFormToolbar
                onConfirm={handleConfirm}
                isLoading={isLoading}
              />
            }
            sx={{ padding: 0 }}
          >
            <MarkdownInput
              height="200px"
              fullWidth
              label={false}
              source="reviewNotes"
            />
          </SimpleForm>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

import React from 'react'
import {
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  TabbedShowLayoutTabs,
  SimpleShowLayout,
  FunctionField,
  NumberField,
  useRecordContext,
  SimpleShowLayoutProps,
  useShowController,
  Filter,
  SelectInput,
} from 'react-admin'
import { MutationsList } from '../../organisms/MutationsList'
import { MutateServiceModal } from '../../organisms/MutateServiceModal'
import { TypeStateField } from '../../atoms/TypeStateField'
import { PageTitle } from '../../atoms/PageTitle'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { ReferenceText } from '../../molecules/ReferenceText'
import { ServiceHealthIndicatorField } from '../../atoms/ServiceHealthIndicatorField'
import { RelatedResourcesPage, IPageMap } from '../../molecules/RelatedResource'
import { Toolbar } from '../../molecules/Toolbar'
import { ServicesHealthList } from '../../organisms/ServicesHealthList'
import { ServiceSpecificationDisplay } from '../../templates/ServiceSpecificationDisplay'
import ClientContractPages from '../ClientContract'
import VendorContractPages from '../VendorContract'
import ServiceSitePages from '../ServiceSite'
import ServicePortPages from '../ServicePort'
import ServiceStatisticPages from '../ServiceStatistic'
import DocumentPages from '../Document'
import MonitorTargetPages from '../MonitorTarget'
import { MarkdownField } from '@react-admin/ra-markdown'
import { ContactsArrayField } from '../../organisms/ContactsArrayField'
import { TextPreSuffixField } from '../../atoms/TextPreSuffixField'
import { CurrencyField } from '../../atoms/CurrencyField'
import { ServiceList } from './List.component'
import {
  Grid,
  Stack,
  Chip,
  Divider,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import { SearchFilter } from '../../atoms/SearchFilter'
import { ProvisioningUpdateModal } from '../../organisms/ProvisioningUpdateModal'
import { ProvisioningUpdatesList } from '../../organisms/ProvisioningUpdatesList'

/**
 * Page to show a single service.
 * @property props The properties of the component.
 * @returns The page to show a single service.
 * @example
 * <ServiceShow {...props} />
 */
export const ServiceShow: React.FC<ShowProps> = (props) => {
  const theme = useTheme()

  const shouldShowDivider = useMediaQuery(theme.breakpoints.down('lg'))

  const columnsBreakpoints = { xs: 1, sm: 3, md: 5, lg: 9 } as const

  const { record } = useShowController(props)

  const RowDivider = () => (
    <Grid item {...columnsBreakpoints}>
      {shouldShowDivider && <Divider />}
    </Grid>
  )
  return (
    <Show
      title={<PageTitle prefix="Service" />}
      actions={
        <Toolbar showEdit>
          {record?.state === 'provisioning' && <ProvisioningUpdateModal />}
          <MutateServiceModal />
        </Toolbar>
      }
    >
      <TabbedShowLayout
        tabs={
          <TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" />
        }
        {...props}
      >
        <Tab label="general">
          <Grid container columns={columnsBreakpoints}>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <ReferenceText
                  label="Service Category"
                  reference="serviceCategories/list"
                  source="serviceCategoryId"
                  emptyText="N/A"
                  link={false}
                />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <ReferenceText
                  label="Service Type"
                  reference="serviceTypes/list"
                  source="serviceTypeId"
                  emptyText="N/A"
                  link={false}
                />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <TextField source="serviceNo" label="Service No" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <TextField source="name" label="Service Name" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <HighestMrcActiveClientContractShowLayout>
                <DateField
                  label="Billing Start Date"
                  source="periodStart"
                  emptyText="N/A"
                />
              </HighestMrcActiveClientContractShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <TextField
                  source="deliveredAt"
                  label="Delivered At"
                  emptyText="N/A"
                />
              </SimpleShowLayout>
            </Grid>

            <RowDivider />

            <Grid item xs={1}>
              <SimpleShowLayout>
                <TextField
                  source="deliveryWishDate"
                  label="Service Delivery Wish Date"
                  emptyText="N/A"
                />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <TextField
                  source="provisionedAt"
                  label="Provisioned At"
                  emptyText="N/A"
                />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <TextField
                  source="cancelledAt"
                  label="Cancelled At"
                  emptyText="N/A"
                />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <TextField
                  source="decommissionedAt"
                  label="Decomissioned At"
                  emptyText="N/A"
                />
              </SimpleShowLayout>
            </Grid>

            <RowDivider />

            <Grid item xs={1}>
              <SimpleShowLayout>
                <TextField
                  label="Site Address"
                  source="sites.0.address"
                  emptyText="N/A"
                />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <TypeStateField source="state" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <ServiceHealthIndicatorField label="Health" />
              </SimpleShowLayout>
            </Grid>

            <RowDivider />

            <Grid item xs={1}>
              <SimpleShowLayout>
                <TextField
                  label="Download Speed"
                  source="specifications.bandwidthDown"
                  emptyText="N/A"
                />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <TextField
                  label="Upload Speed"
                  source="specifications.bandwidthUp"
                  emptyText="N/A"
                />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <FunctionField
                  label="Ports → Network"
                  render={({
                    ports,
                  }: {
                    ports?: { networks: { network: string }[] }[]
                  }) =>
                    ports && ports.length > 0 && ports[0].networks?.[0]?.network
                      ? ports.map((port) =>
                          port.networks.map(({ network }) => (
                            <Chip variant="outlined" label={network} />
                          ))
                        )
                      : 'N/A'
                  }
                />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <FunctionField
                  label="Ports → Address"
                  render={({
                    ports,
                  }: {
                    ports?: { networks: { address: string }[] }[]
                  }) =>
                    ports && ports.length > 0 && ports[0].networks?.[0]?.address
                      ? ports.map((port) =>
                          port.networks.map(({ address }) => (
                            <Chip variant="outlined" label={address} />
                          ))
                        )
                      : 'N/A'
                  }
                />
              </SimpleShowLayout>
            </Grid>

            <RowDivider />

            <Grid item xs={1}>
              <SimpleShowLayout>
                <NumberField
                  source="specifications.ipPrefixV4"
                  label="Public IPv4 subnet prefix size"
                  emptyText="N/A"
                />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <TextField
                  label="Port Speed"
                  source="ports.0.speed"
                  emptyText="N/A"
                />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <TextField
                  label="Port Type"
                  source="ports.0.type"
                  emptyText="N/A"
                />
              </SimpleShowLayout>
            </Grid>

            <RowDivider />

            <Grid item xs={1}>
              <SimpleShowLayout>
                <ReferenceText
                  label="Access Technology"
                  reference="accessTechnologies/list"
                  source="accessTechnologyId"
                  emptyText="N/A"
                  link={false}
                />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <TextField
                  label="Last Mile Provider"
                  source="specifications.lastMileProvider"
                  emptyText="N/A"
                />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <TextField
                  label="Internet Service Provider"
                  source="specifications.internetServiceProvider"
                  emptyText="N/A"
                />
              </SimpleShowLayout>
            </Grid>

            <Grid item xs={1}>
              <SimpleShowLayout>
                <TextField source="diversityRole" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <ReferenceText
                  label="Diverse to"
                  source="diverseToId"
                  reference="services"
                  emptyText="N/A"
                  link="show"
                />
              </SimpleShowLayout>
            </Grid>

            <RowDivider />

            <Grid item xs={1}>
              <SimpleShowLayout>
                <ReferenceChip
                  referenceSource="clientId"
                  tooltipSource="clientNo"
                  source="name"
                  reference="clients"
                  label="Client"
                  emptyText="N/A"
                  link="show"
                  chipSource="name"
                  removeDoubleLabel
                />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <TextField
                  label="Client Reference"
                  source="reference"
                  emptyText="N/A"
                />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <ReferenceChip
                  referenceSource="endCustomerId"
                  tooltipSource="name"
                  source="name"
                  reference="endCustomers"
                  label="End Customer Name"
                  emptyText="N/A"
                  link="show"
                  chipSource="name"
                  removeDoubleLabel
                />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <HighestMrcActiveClientContractShowLayout>
                <TextField
                  label="Contract No"
                  source="contractNo"
                  emptyText="N/A"
                />
              </HighestMrcActiveClientContractShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <TextField label="Contract Signed Date" emptyText="N/A" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <HighestMrcActiveClientContractShowLayout>
                <TextField
                  label="Contract Term"
                  source="contractPeriod"
                  emptyText="N/A"
                />
              </HighestMrcActiveClientContractShowLayout>
            </Grid>

            <RowDivider />

            <Grid item xs={1}>
              <SimpleShowLayout>
                <ReferenceChip
                  referenceSource="vendorId"
                  tooltipSource="name"
                  source="name"
                  reference="vendors"
                  label="Vendor"
                  emptyText="N/A"
                  link="show"
                  chipSource="name"
                  removeDoubleLabel
                />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <HighestMrcActiveVendorContract>
                <TextField
                  label="Contract No"
                  source="contractNo"
                  emptyText="N/A"
                />
              </HighestMrcActiveVendorContract>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <TextField label="Contract Signed Date" emptyText="N/A" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <HighestMrcActiveVendorContract>
                <DateField
                  label="Period Start Date"
                  source="periodStart"
                  emptyText="N/A"
                />
              </HighestMrcActiveVendorContract>
            </Grid>
            <Grid item xs={1}>
              <HighestMrcActiveVendorContract>
                <TextField
                  label="Contract Term"
                  source="contractPeriod"
                  emptyText="N/A"
                />
              </HighestMrcActiveVendorContract>
            </Grid>

            <RowDivider />

            <Grid item xs={1}>
              <Stack>
                <HighestMrcActiveVendorContract>
                  <CurrencyField
                    currency="purchaseCurrency"
                    label="Purchase NRC"
                    source="purchaseNrc"
                    emptyText="N/A"
                  />
                </HighestMrcActiveVendorContract>
                <HighestMrcActiveVendorContract>
                  <CurrencyField
                    currency="EUR"
                    label="Purchase NRC (EUR)"
                    source="purchaseValueNrc"
                    emptyText="N/A"
                  />
                </HighestMrcActiveVendorContract>
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <Stack>
                <HighestMrcActiveVendorContract>
                  <CurrencyField
                    currency="purchaseCurrency"
                    label="Purchase MRC"
                    source="purchaseMrc"
                    emptyText="N/A"
                  />
                </HighestMrcActiveVendorContract>
                <HighestMrcActiveVendorContract>
                  <CurrencyField
                    currency="EUR"
                    label="Purchase MRC (EUR)"
                    source="purchaseValueMrc"
                    emptyText="N/A"
                  />
                </HighestMrcActiveVendorContract>
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <Stack>
                <HighestMrcActiveClientContractShowLayout>
                  <CurrencyField
                    currency="salesCurrency"
                    label="Sales NRC"
                    source="salesNrc"
                    emptyText="N/A"
                  />
                </HighestMrcActiveClientContractShowLayout>
                <HighestMrcActiveClientContractShowLayout>
                  <CurrencyField
                    currency="EUR"
                    label="Sales NRC (EUR)"
                    source="salesValueNrc"
                    emptyText="N/A"
                  />
                </HighestMrcActiveClientContractShowLayout>
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <Stack>
                <HighestMrcActiveClientContractShowLayout>
                  <CurrencyField
                    currency="salesCurrency"
                    label="Sales MRC"
                    source="salesMrc"
                    emptyText="N/A"
                  />
                </HighestMrcActiveClientContractShowLayout>
                <HighestMrcActiveClientContractShowLayout>
                  <CurrencyField
                    currency="EUR"
                    label="Sales MRC (EUR)"
                    source="salesValueMrc"
                    emptyText="N/A"
                  />
                </HighestMrcActiveClientContractShowLayout>
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <Stack>
                <HighestMrcActiveClientContractShowLayout>
                  <CurrencyField
                    currency="salesCurrency"
                    label="Sales Margin NRC"
                    source="salesMarginNrc"
                    emptyText="N/A"
                  />
                </HighestMrcActiveClientContractShowLayout>
                <HighestMrcActiveClientContractShowLayout>
                  <CurrencyField
                    currency="EUR"
                    source="salesValueMarginNrc"
                    label="Sales Margin NRC (EUR)"
                    emptyText="N/A"
                  />
                </HighestMrcActiveClientContractShowLayout>
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <Stack>
                <HighestMrcActiveClientContractShowLayout>
                  <CurrencyField
                    currency="salesCurrency"
                    source="salesMarginMrc"
                    label="Sales Margin MRC"
                  />
                </HighestMrcActiveClientContractShowLayout>
                <HighestMrcActiveClientContractShowLayout>
                  <CurrencyField
                    currency="EUR"
                    source="salesValueMarginMrc"
                    label="Sales Margin MRC (EUR)"
                    emptyText="N/A"
                  />
                </HighestMrcActiveClientContractShowLayout>
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <TextField label="Sales Margin NRC %" emptyText="N/A" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <TextField label="Sales Margin MRC %" emptyText="N/A" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <HighestMrcActiveClientContractShowLayout>
                <TextPreSuffixField
                  source="salesVat"
                  label="Sales VAT"
                  emptyText="N/A"
                  suffix="%"
                />
              </HighestMrcActiveClientContractShowLayout>
            </Grid>

            <RowDivider />

            <Grid item xs={1}>
              <SimpleShowLayout>
                <DateField source="updatedAt" showTime />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <DateField source="createdAt" showTime />
              </SimpleShowLayout>
            </Grid>
          </Grid>
        </Tab>
        <Tab label="specifications">
          <ServiceSpecificationDisplay source="specifications" />
        </Tab>
        <Tab label="contacts">
          <ContactsArrayField
            reference="clientContacts"
            source="clientContacts"
          />
          <ContactsArrayField
            reference="vendorContacts"
            source="vendorContacts"
          />
        </Tab>
        <Tab label="notes">
          <TextField source="notes" label="Internal notes" emptyText="N/A" />
          <TextField
            source="notesForClient"
            label="Notes for client"
            emptyText="N/A"
          />
          <MarkdownField source="deliveryNotes" emptyText="N/A" />
        </Tab>
        <Tab label="health">
          <ServicesHealthList />
        </Tab>
        <Tab label="history">
          <MutationsList showActor type="history" />
        </Tab>
        <Tab label="related">
          <FunctionField
            render={(
              service: Parameters<
                typeof getRelatedResourceNameToComponentMap
              >[0]
            ) => (
              <RelatedResourcesPage
                pages={getRelatedResourceNameToComponentMap(service)}
                resource="service"
              />
            )}
          />
        </Tab>
        <Tab label="provisioning updates">
          <ProvisioningUpdatesList />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

/**
 * Returns a map of related resources to their respective components.
 * Used by the RelatedResourcesPage component.
 */
function getRelatedResourceNameToComponentMap(service: {
  id: string
}): IPageMap {
  const ClientContractsList = ClientContractPages.list
  const ServiceSitesList = ServiceSitePages.list
  const DocumentsList = DocumentPages.list
  const VendorContractsList = VendorContractPages.list
  const ServicePortsList = ServicePortPages.list
  const ServiceStatisticsList = ServiceStatisticPages.list
  const MonitorTargetsList = MonitorTargetPages.list

  return {
    Services: (
      <ServiceList
        disableSyncWithLocation
        actions={false}
        children={undefined}
        resource={`services/${service.id}/related`}
        filters={
          <Filter>
            <SelectInput
              source="relationship"
              label="Type of relation"
              emptyText="All"
              emptyValue={'All'}
              choices={[
                { id: 'relatedToThisService', name: 'Related To This Service' },
                { id: 'diverseToThisService', name: 'Diverse To This Service' },
                {
                  id: 'relatedToOtherService',
                  name: 'Related To Other Service',
                },
                {
                  id: 'diverseToOtherService',
                  name: 'Diverse To Other Service',
                },
              ]}
              alwaysOn
            />
          </Filter>
        }
      />
    ),
    'Client Contracts': (
      <ClientContractsList
        title={' '}
        disableSyncWithLocation
        children={undefined}
        actions={false}
        resource="clientContracts"
        filter={{ serviceId: service.id }}
        filters={<SearchFilter />}
      />
    ),
    'Vendor Contracts': (
      <VendorContractsList
        title={' '}
        disableSyncWithLocation
        children={undefined}
        actions={false}
        resource="vendorContracts"
        filter={{ serviceId: service.id }}
        filters={<SearchFilter />}
      />
    ),
    'Service Sites': (
      <ServiceSitesList
        title={' '}
        actions={false}
        disableSyncWithLocation
        children={undefined}
        resource="serviceSites"
        filter={{ serviceIds: service.id }}
        filters={<SearchFilter />}
      />
    ),
    Ports: (
      <ServicePortsList
        title={' '}
        actions={false}
        disableSyncWithLocation
        children={undefined}
        resource="servicePorts"
        filter={{ serviceId: service.id }}
        filters={<SearchFilter />}
      />
    ),
    Statistics: (
      <ServiceStatisticsList
        title={' '}
        actions={false}
        disableSyncWithLocation
        children={undefined}
        resource="serviceStatistics"
        filter={{ serviceId: service.id }}
        filters={<SearchFilter />}
      />
    ),
    Documents: (
      <DocumentsList
        title={' '}
        actions={false}
        disableSyncWithLocation
        children={undefined}
        resource="documents"
        filter={{ serviceId: service.id }}
      />
    ),
    'Monitor Targets': (
      <MonitorTargetsList
        title={' '}
        actions={false}
        disableSyncWithLocation
        children={undefined}
        resource="monitorTargets"
        filter={{ serviceIds: service.id }}
        filters={<SearchFilter />}
      />
    ),
  }
}

/**
 * Expects the record in context to be a Service.
 * Returns a SimpleShowLayout where the record in context is the highest MRC active client contract of the service.
 */
function HighestMrcActiveClientContractShowLayout(
  props: SimpleShowLayoutProps
) {
  const record = useRecordContext()

  const highestMrcActiveClientContract = record.clientContracts
    ?.filter((contract: { state: string }) => contract.state === 'active')
    ?.reduce(
      (highest: { salesMrc?: number }, current: { salesMrc?: number }) =>
        (current.salesMrc ?? 0) > (highest.salesMrc ?? 0) ? current : highest,
      {}
    )

  return <SimpleShowLayout {...props} record={highestMrcActiveClientContract} />
}

/**
 * Expects the record in context to be a Service.
 * Returns a SimpleShowLayout where the record in context is the highest MRC active vendor contract of the service.
 */
function HighestMrcActiveVendorContract(props: SimpleShowLayoutProps) {
  const record = useRecordContext()

  const highestMrcActiveVendorContract = record.vendorContracts
    ?.filter((contract: { state: string }) => contract.state === 'active')
    ?.reduce(
      (highest: { purchaseMrc?: number }, current: { purchaseMrc?: number }) =>
        (current.purchaseMrc ?? 0) > (highest.purchaseMrc ?? 0)
          ? current
          : highest,
      {}
    )

  return <SimpleShowLayout {...props} record={highestMrcActiveVendorContract} />
}

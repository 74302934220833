import React from 'react'
import {
  IconMenu,
  MenuItemList,
  MenuItemNode,
} from '@react-admin/ra-navigation'
import { CardContent } from '@mui/material'
import RequestsIcon from '@mui/icons-material/AllInboxOutlined'

export const RequestsMenuItem: React.FC = () => {
  return (
    <IconMenu.Item name="requests" label="Requests" icon={<RequestsIcon />}>
      <CardContent>
        <MenuItemList>
          <MenuItemNode
            name="quoteRequests"
            to={'/quote-requests'}
            label="Quote Requests"
          />
          <MenuItemNode
            name="requestServices"
            to={'/requestServices'}
            label="Request Services"
          />
        </MenuItemList>
      </CardContent>
    </IconMenu.Item>
  )
}

import React from 'react'
import {
  DateField,
  useListContext,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  RowClickFunction,
} from 'react-admin'
import { RequestServiceDatagridProps } from './RequestServiceDatagrid.types'
import { TypeStateField } from '../../atoms/TypeStateField'
import { EllipsedTextField } from '../../atoms/EllipsedField'
import { ReferenceChip } from '../ReferenceChip'
import { ConfigurableLabelledDatagrid } from '../../atoms/LabelledDatagrid'

export const RequestServiceDatagrid: React.FC<RequestServiceDatagridProps> = ({
  requestId,
}) => {
  const { data } = useListContext()

  const handleRowClick: RowClickFunction = (id) => {
    if (requestId) {
      return `/quote-requests/${requestId}/services/${id}`
    }
    const foundRequest = (data as { requestId: string; id: string }[]).find(
      (item) => item.id === id
    )?.requestId
    if (!foundRequest) return ''
    return `/quote-requests/${foundRequest}/services/${id}`
  }

  return (
    <ConfigurableLabelledDatagrid
      rowClick={handleRowClick}
      bulkActionButtons={false}
      expandSingle
    >
      <ReferenceChip
        referenceSource={'requestId'}
        tooltipSource="name"
        source="requestId"
        chipSource="requestNo"
        label={'Request'}
        reference="requests"
        emptyText={'N/A'}
        removeDoubleLabel
        link={(record) => `/quote-requests/${record.id}`}
        sortable
      />
      <TypeStateField source="state" />
      <EllipsedTextField source="processState" maxWidth={250} emptyText="N/A" />
      <ReferenceChip
        referenceSource={'request.clientId'}
        tooltipSource="clientNo"
        source="request.clientId"
        chipSource="name"
        label={'Client'}
        reference="clients"
        emptyText={'N/A'}
        removeDoubleLabel
        sortable={false}
      />
      <EllipsedTextField source="name" emptyText="N/A" />
      <ReferenceArrayField
        source="assigneeIds"
        reference="users/list"
        label="Assignee(s)"
        sortable={false}
      >
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <DateField source="updatedAt" showTime />
    </ConfigurableLabelledDatagrid>
  )
}

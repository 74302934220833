import React, { useEffect, useMemo } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Stack,
  InputAdornment,
} from '@mui/material'
import {
  SimpleForm,
  SimpleShowLayout,
  TextField,
  ChipField,
  SelectInput,
  NumberInput,
  useRecordContext,
  ReferenceInput,
  AutocompleteInput,
  ReferenceField,
  FunctionField,
  TextInput,
  useRefresh,
  useNotify,
  useDataProvider,
} from 'react-admin'
import get from 'lodash/get'
import ExpandAccordionIcon from '@mui/icons-material/ExpandMore'
import DoneIcon from '@mui/icons-material/CheckBoxOutlined'
import ToDoIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { CurrencyField } from '../../atoms/CurrencyField'
import { ServiceSpecificationInputsTable } from './ServiceSpecificationInputsTable.component'
import { ProcurementFormToolbar } from './ProcurementFormToolbar.component'

interface ProductProcurementAccordionProps {
  setIsDirty: (value: boolean) => void
  onSave?: () => void
}

export const ProductProcurementAccordion = (
  props: ProductProcurementAccordionProps
) => {
  const record = useRecordContext()
  const refresh = useRefresh()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const [isLoading, setIsLoading] = React.useState(false)

  record.offer = record.vendorOffers?.[0]

  const handleSave = async (formData: any) => {
    setIsLoading(true)

    try {
      const data = await dataProvider.customRequest(
        'requestProducts',
        `${record.id}/vendorOffer`,
        {
          data: { ...formData?.offer },
          method: 'PUT',
        }
      )
      if (data?.json?.data?.id) {
        notify('Vendor offer has been saved')
        refresh()
      }
    } catch (error: any) {
      notify(error.message, { type: 'error' })
    }

    setIsLoading(false)

    props.onSave?.()
  }

  const handleSetIsDirty = (value: boolean) => {
    props.setIsDirty(value)
  }

  const handleConfirm = async () => {
    setIsLoading(true)

    try {
      const data = await dataProvider.customRequest(
        'requestProducts',
        `${record.id}/vendorOffer`,
        {
          data: { action: 'confirm' },
          method: 'PUT',
        }
      )
      if (data?.json?.data?.id) {
        notify('Vendor offer has been confirmed')
        refresh()
      }
    } catch (error: any) {
      notify(error.message, { type: 'error' })
    }

    setIsLoading(false)
  }

  const isDisabled = useMemo(() => {
    return !record.requestService?.isReviewConfirmed || record.isOfferConfirmed
  }, [record])

  const [isExpanded, setIsExpanded] = React.useState(false)

  useEffect(() => {
    if (record.requestService?.isReviewConfirmed && !record.isOfferConfirmed) {
      setIsExpanded(true)
    }
  }, [record])

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded)
  }

  if (!record) return null

  return (
    <Accordion
      expanded={record.isCancelled ? false : isExpanded}
      disableGutters
      disabled={record.isCancelled}
    >
      <AccordionSummary
        expandIcon={<ExpandAccordionIcon color="info" />}
        onClick={toggleAccordion}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6" alignItems="center" display="flex" gap={1}>
              {record.isOfferConfirmed ? (
                <DoneIcon sx={{ mr: 1 }} />
              ) : (
                <ToDoIcon sx={{ mr: 1 }} />
              )}{' '}
              Procurement: Firm Offer
            </Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="subtitle2">Source</Typography>
              </Grid>
              <Grid item>
                <ChipField
                  source="source"
                  sx={{ textTransform: 'capitalize' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <SimpleForm
          record={record}
          onSubmit={handleSave}
          toolbar={
            <ProcurementFormToolbar
              onConfirm={handleConfirm}
              isLoading={isLoading}
              setIsDirty={handleSetIsDirty}
            />
          }
          sx={{ padding: 0, '& legend': { display: 'none' } }}
        >
          <SimpleShowLayout sx={{ padding: 0, width: '100%' }}>
            <Stack spacing={1}>
              <Grid container spacing={4} paddingX={2}>
                <Grid item xs={12} xl={6}>
                  <TableContainer component={Box}>
                    <Table>
                      <TableBody
                        sx={{
                          'td, th': {
                            border: 0,
                            padding: 0.5,
                          },
                        }}
                      >
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            width={'40%'}
                          ></TableCell>
                          <TableCell width={'25%'}>
                            <Typography variant="body1" color="GrayText">
                              Product
                            </Typography>
                          </TableCell>
                          <TableCell width={'35%'}>
                            <Typography variant="body1" color="GrayText">
                              Firm Offer
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Typography variant="body2" fontWeight="bold">
                              Vendor
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <ReferenceField
                              source="vendorId"
                              reference="vendors"
                              link={false}
                            >
                              <TextField
                                variant="body2"
                                source="name"
                                emptyText="N/A"
                              />
                            </ReferenceField>
                          </TableCell>
                          <TableCell>
                            <ReferenceField
                              source="vendorId"
                              reference="vendors"
                              link={false}
                            >
                              <FunctionField
                                render={(recordA: any) => (
                                  <TextInput
                                    disabled
                                    helperText={false}
                                    size="small"
                                    source="vendor.name"
                                    label={false}
                                    variant="outlined"
                                    fullWidth
                                    defaultValue={recordA.name}
                                  />
                                )}
                              />
                            </ReferenceField>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Typography variant="body2">
                              Internet Service Provider
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <TextField
                              variant="body2"
                              source="specifications.internetServiceProvider"
                              emptyText="N/A"
                            />
                          </TableCell>
                          <TableCell>
                            <ReferenceInput
                              source="offer.internetServiceProviderId"
                              reference="internetServiceProviders/list"
                            >
                              <AutocompleteInput
                                disabled={isDisabled}
                                helperText={false}
                                label={false}
                                size="small"
                                optionText="name"
                                variant="outlined"
                                fullWidth
                              />
                            </ReferenceInput>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Typography variant="body2">
                              Last Mile Provider
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <TextField
                              variant="body2"
                              source="specifications.lastMileProvider"
                              emptyText="N/A"
                            />
                          </TableCell>
                          <TableCell>
                            <ReferenceInput
                              source="offer.lastMileProviderId"
                              reference="lastMileProviders/list"
                            >
                              <AutocompleteInput
                                disabled={isDisabled}
                                helperText={false}
                                label={false}
                                size="small"
                                optionText="name"
                                variant="outlined"
                                fullWidth
                              />
                            </ReferenceInput>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Typography variant="body2" fontWeight="bold">
                              Service type
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <TextField
                              variant="body2"
                              source="serviceType.name"
                              emptyText="N/A"
                            />
                          </TableCell>
                          <TableCell>
                            <ReferenceInput
                              source="offer.serviceTypeId"
                              reference="serviceTypes"
                            >
                              <FunctionField
                                render={(recordA: any) => (
                                  <TextInput
                                    disabled
                                    helperText={false}
                                    size="small"
                                    source="serviceType.name"
                                    label={false}
                                    variant="outlined"
                                    fullWidth
                                    defaultValue={get(record, 'serviceTypeId')}
                                  />
                                )}
                              />
                            </ReferenceInput>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Typography variant="body2" fontWeight="bold">
                              Access technology
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <TextField
                              variant="body2"
                              source="accessTechnology.name"
                              emptyText="N/A"
                            />
                          </TableCell>
                          <TableCell>
                            <ReferenceInput
                              source="offer.accessTechnologyId"
                              reference="accessTechnologies"
                            >
                              <FunctionField
                                render={(recordA: any) => (
                                  <TextInput
                                    disabled
                                    helperText={false}
                                    size="small"
                                    source="accessTechnology.name"
                                    label={false}
                                    variant="outlined"
                                    fullWidth
                                    defaultValue={'recordA.name'}
                                  />
                                )}
                              />
                            </ReferenceInput>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Typography variant="body2" fontWeight="bold">
                              Download bandwidth
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <FunctionField
                              variant="body2"
                              render={(record: {
                                specifications: {
                                  bandwidthDown: number
                                }
                              }) =>
                                `${record.specifications.bandwidthDown} Mbps`
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <FunctionField
                              render={(recordA: any) => (
                                <TextInput
                                  disabled
                                  helperText={false}
                                  size="small"
                                  source="offer.specifications.bandwidthDown"
                                  label={false}
                                  variant="outlined"
                                  fullWidth
                                  defaultValue={get(
                                    record,
                                    'specifications.bandwidthDown'
                                  )}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        Mbps
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Typography variant="body2" fontWeight="bold">
                              Upload bandwidth
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <FunctionField
                              variant="body2"
                              render={(record: {
                                specifications: {
                                  bandwidthUp: number
                                }
                              }) => `${record.specifications.bandwidthUp} Mbps`}
                            />
                          </TableCell>
                          <TableCell>
                            <FunctionField
                              render={(recordA: any) => (
                                <TextInput
                                  disabled
                                  helperText={false}
                                  size="small"
                                  source="offer.specifications.bandwidthUp"
                                  label={false}
                                  variant="outlined"
                                  fullWidth
                                  defaultValue={get(
                                    record,
                                    'specifications.bandwidthUp'
                                  )}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        Mbps
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Typography variant="body2" fontWeight="bold">
                              Contention ratio
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <FunctionField
                              variant="body2"
                              render={(record: {
                                specifications: {
                                  contentionRatio: number
                                }
                              }) =>
                                record.specifications.contentionRatio
                                  ? `${record.specifications.contentionRatio}:1`
                                  : 'N/A'
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <NumberInput
                              disabled={isDisabled}
                              helperText={false}
                              size="small"
                              source="offer.specifications.contentionRatio"
                              label={false}
                              variant="outlined"
                              fullWidth
                              defaultValue={get(
                                record,
                                'specifications.contentionRatio'
                              )}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    1:
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Typography variant="body2" fontWeight="bold">
                              IPv4 prefix size
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <FunctionField
                              variant="body2"
                              render={(record: {
                                specifications: {
                                  ipPrefixV4: string
                                }
                              }) => `/${record.specifications.ipPrefixV4}`}
                            />
                          </TableCell>
                          <TableCell>
                            <SelectInput
                              disabled={isDisabled}
                              helperText={false}
                              size="small"
                              source="offer.specifications.ipPrefixV4"
                              label={false}
                              variant="outlined"
                              fullWidth
                              defaultValue={get(
                                record,
                                'specifications.ipPrefixV4'
                              )}
                              choices={[
                                { id: 32, name: '/32' },
                                { id: 31, name: '/31' },
                                { id: 30, name: '/30' },
                                { id: 29, name: '/29' },
                                { id: 28, name: '/28' },
                                { id: 27, name: '/27' },
                                { id: 26, name: '/26' },
                              ]}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} xl={6}>
                  <TableContainer component={Box}>
                    <Table>
                      <TableBody
                        sx={{
                          'td, th': {
                            border: 0,
                            padding: 0.5,
                          },
                        }}
                      >
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            width="40%"
                          ></TableCell>
                          <TableCell width="25%">
                            <Typography variant="body1" color="GrayText">
                              Product
                            </Typography>
                          </TableCell>
                          <TableCell width={'35%'}>
                            <Typography variant="body1" color="GrayText">
                              Firm Offer
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Typography variant="body2" fontWeight="bold">
                              Contract term
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <FunctionField
                              variant="body2"
                              render={(record: {
                                contractTerms: {
                                  contractPeriod: number
                                }
                              }) =>
                                `${record.contractTerms.contractPeriod} months`
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <FunctionField
                              render={(recordA: any) => (
                                <TextInput
                                  disabled
                                  helperText={false}
                                  size="small"
                                  source="offer.contractTerms.contractPeriod"
                                  label={false}
                                  variant="outlined"
                                  fullWidth
                                  defaultValue={get(
                                    record,
                                    'contractTerms.contractPeriod'
                                  )}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        Months
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Typography variant="body2" fontWeight="bold">
                              Purchase currency
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <TextField
                              variant="body2"
                              source="purchaseCurrency"
                              label="Purchase Currency"
                              emptyText="N/A"
                            />
                          </TableCell>
                          <TableCell>
                            <ReferenceInput
                              source="offer.purchaseCurrency"
                              reference="currencies/list"
                            >
                              <AutocompleteInput
                                disabled={isDisabled}
                                helperText={false}
                                label={false}
                                size="small"
                                optionText="name"
                                variant="outlined"
                                fullWidth
                                defaultValue={get(record, 'purchaseCurrency')}
                              />
                            </ReferenceInput>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Typography variant="body2" fontWeight="bold">
                              NRC purchase
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <CurrencyField
                              variant="body2"
                              source="purchaseNrc"
                              currency="purchaseCurrency"
                              label="Purchase NRC"
                              emptyText="N/A"
                            />
                            <Typography variant="body2">
                              {record.applyVatOnMrc ? (
                                'excluding taxes'
                              ) : (
                                <strong>including taxes</strong>
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <NumberInput
                              disabled={isDisabled}
                              helperText={false}
                              size="small"
                              source="offer.purchaseNrc"
                              label={false}
                              variant="outlined"
                              fullWidth
                              defaultValue={get(record, 'purchaseNrc')}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    {record.purchaseCurrency}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Typography variant="body2" fontWeight="bold">
                              MRC purchase
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <CurrencyField
                              variant="body2"
                              source="purchaseMrc"
                              currency="purchaseCurrency"
                              label="Purchase MRC"
                              emptyText="N/A"
                            />
                            <Typography variant="body2">
                              {record.applyVatOnMrc ? (
                                'excluding taxes'
                              ) : (
                                <strong>including taxes</strong>
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <NumberInput
                              disabled={isDisabled}
                              helperText={false}
                              size="small"
                              source="offer.purchaseMrc"
                              label={false}
                              variant="outlined"
                              fullWidth
                              defaultValue={get(record, 'purchaseMrc')}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    {record.purchaseCurrency}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Typography variant="body2" fontWeight="bold">
                              Service uptime
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <FunctionField
                              variant="body2"
                              render={(record: {
                                contractTerms: {
                                  serviceUptime: number
                                }
                              }) => `${record.contractTerms.serviceUptime}%`}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberInput
                              disabled={isDisabled}
                              helperText={false}
                              size="small"
                              source="offer.contractTerms.serviceUptime"
                              label={false}
                              variant="outlined"
                              fullWidth
                              defaultValue={get(
                                record,
                                'contractTerms.serviceUptime'
                              )}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Typography variant="body2" fontWeight="bold">
                              MTTR
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <FunctionField
                              variant="body2"
                              render={(record: {
                                contractTerms: {
                                  meanTimeToRepair: number
                                }
                              }) =>
                                record.contractTerms.meanTimeToRepair
                                  ? `${record.contractTerms.meanTimeToRepair} hours`
                                  : 'N/A'
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <NumberInput
                              disabled={isDisabled}
                              helperText={false}
                              size="small"
                              source="offer.contractTerms.meanTimeToRepair"
                              label={false}
                              variant="outlined"
                              fullWidth
                              defaultValue={get(
                                record,
                                'contractTerms.meanTimeToRepair'
                              )}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    hours
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Typography variant="body2" fontWeight="bold">
                              Lead time
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <FunctionField
                              variant="body2"
                              render={(record: {
                                contractTerms: {
                                  leadTime: number
                                }
                              }) => `${record.contractTerms.leadTime} weeks`}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberInput
                              disabled={isDisabled}
                              helperText={false}
                              size="small"
                              source="offer.contractTerms.leadTime"
                              label={false}
                              variant="outlined"
                              fullWidth
                              defaultValue={get(
                                record,
                                'contractTerms.leadTime'
                              )}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    weeks
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Typography variant="body2" fontWeight="bold">
                              Proactive support 24x7
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <FunctionField
                              variant="body2"
                              render={(record: {
                                contractTerms: {
                                  supportLevel: string
                                }
                              }) =>
                                record.contractTerms.supportLevel ===
                                'proactive'
                                  ? 'Yes'
                                  : 'No'
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <SelectInput
                              disabled={isDisabled}
                              helperText={false}
                              size="small"
                              source="offer.contractTerms.supportLevel"
                              choices={[
                                { id: 'standard', name: 'No' },
                                { id: 'proactive', name: 'Yes' },
                              ]}
                              label={false}
                              variant="outlined"
                              fullWidth
                              defaultValue={get(
                                record,
                                'contractTerms.supportLevel',
                                'standard'
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              <Accordion defaultExpanded={false} elevation={0} disableGutters>
                <AccordionSummary
                  expandIcon={<ExpandAccordionIcon color="info" />}
                >
                  <Typography variant="subtitle1">
                    More specifications
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ServiceSpecificationInputsTable disabled={isDisabled} />
                </AccordionDetails>
              </Accordion>
              <Accordion
                defaultExpanded={record?.offer?.notesForClient}
                elevation={0}
                disableGutters
              >
                <AccordionSummary
                  expandIcon={<ExpandAccordionIcon color="info" />}
                >
                  <Typography variant="subtitle1">
                    Notes for customer
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextInput
                    multiline
                    variant="outlined"
                    source="offer.notesForClient"
                    label={false}
                    fullWidth
                    disabled={isDisabled}
                    defaultValue={null}
                  />
                </AccordionDetails>
              </Accordion>
            </Stack>
          </SimpleShowLayout>
        </SimpleForm>
      </AccordionDetails>
    </Accordion>
  )
}

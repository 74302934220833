import React, { useEffect } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material'
import {
  SimpleForm,
  useRecordContext,
  useRefresh,
  useNotify,
  useDataProvider,
  ChipField,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  ReferenceArrayField,
  SingleFieldList,
} from 'react-admin'
import ExpandAccordionIcon from '@mui/icons-material/ExpandMore'
import DoneIcon from '@mui/icons-material/CheckBoxOutlined'
import ToDoIcon from '@mui/icons-material/CheckBoxOutlineBlank'

export const RequestAssignmentAccordion: React.FC = () => {
  const record = useRecordContext()
  const refresh = useRefresh()
  const notify = useNotify()
  const dataProvider = useDataProvider()

  const handleAssigneesChange = async (assigneeIds: string[]) => {
    try {
      await dataProvider.customRequest('requestServices', record.id, {
        data: { assigneeIds },
        method: 'PUT',
      })
      notify('Assignees updated')
      refresh()
    } catch (e: any) {
      notify(`Could not update assignees: ${e.message}`, { type: 'error' })
    }
  }

  const [isExpanded, setIsExpanded] = React.useState(true)

  useEffect(() => {
    if (record?.assigneeIds?.length > 0) {
      setIsExpanded(false)
    }
  }, [record])

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded)
  }

  if (!record) return null

  return (
    <Accordion expanded={isExpanded} disableGutters>
      <AccordionSummary
        expandIcon={<ExpandAccordionIcon color="info" />}
        onClick={toggleAccordion}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6" alignItems="center" display="flex" gap={1}>
              {record?.assigneeIds?.length > 0 ? (
                <DoneIcon sx={{ mr: 1 }} />
              ) : (
                <ToDoIcon sx={{ mr: 1 }} />
              )}
              Request: Assignment
            </Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <ReferenceArrayField
                  reference="users/list"
                  source="assigneeIds"
                >
                  <SingleFieldList>
                    <ChipField label={false} source="name" />
                  </SingleFieldList>
                </ReferenceArrayField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <SimpleForm
          toolbar={false}
          sx={{
            ':last-child': { padding: 0 },
          }}
        >
          <ReferenceArrayInput
            source="assigneeIds"
            reference="users/list"
            sx={{ margin: 0 }}
            filter={{
              role: {
                in: [
                  'gnx-procurement',
                  'gnx-sales',
                  'gnx-admin',
                  'admin',
                  'sales',
                  'procurement',
                ],
              },
            }}
          >
            <AutocompleteArrayInput
              variant="outlined"
              fullWidth
              label="Assignee(s)"
              optionText="name"
              optionValue="id"
              onChange={handleAssigneesChange}
            />
          </ReferenceArrayInput>
        </SimpleForm>
      </AccordionDetails>
    </Accordion>
  )
}

import React from 'react'
import {
  List,
  ListProps,
  TextField,
  DateField,
  useListContext,
  ExportButton,
  SingleFieldList,
  ReferenceArrayField,
  ChipField,
} from 'react-admin'
import { TypeStateField } from '../../atoms/TypeStateField'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { ServiceHealthIndicatorField } from '../../atoms/ServiceHealthIndicatorField'
import { Toolbar } from '../../molecules/Toolbar'
import { serviceExporter } from '../../particles/exporters/serviceExporter/serviceExporter.funcs'
import { ServiceOrderModal } from '../../organisms/ServiceOrderModal'
import { ConfigurableLabelledDatagrid } from '../../atoms/LabelledDatagrid'
import { useServiceFilters } from '../../particles/filters'
import { EllipsedTextField } from '../../atoms/EllipsedField'
import { ServiceDownloadOverviewModal } from '../../organisms/ServiceDownloadOverviewModal'

/**
 * Page to list services.
 * @property props The properties of the component.
 * @returns The page to list services.
 * @example
 * <ServiceList {...props} />
 */
export const ServiceList: React.FC<ListProps> = (props) => {
  const { total } = useListContext()

  return (
    <List
      empty={false}
      perPage={25}
      filters={useServiceFilters()}
      exporter={serviceExporter}
      actions={
        <Toolbar showSelectColumns showFilter showCreate>
          <ServiceOrderModal />
          <ServiceDownloadOverviewModal />
          <ExportButton disabled={total === 0} />
        </Toolbar>
      }
      sort={{ field: 'createdAt', order: 'DESC' }}
      {...props}
    >
      <ConfigurableLabelledDatagrid
        rowClick={(id) => `/services/${id}/show`}
        omit={[
          'vendor',
          'countryCode',
          'vendorServiceNo',
          'reference',
          'projectId',
          'serviceCategoryId',
          'serviceTypeId',
          'accessTechnologyId',
          'updatedAt',
        ]}
      >
        <TextField source="serviceNo" />
        <TextField source="vendorServiceNo" emptyText="N/A" />
        <TypeStateField source="state" fullWidth />
        <ServiceHealthIndicatorField
          onlyIcon
          source="health"
          label="Health"
          sortable={false}
        />
        <EllipsedTextField source="name" />
        <EllipsedTextField source="reference" emptyText="N/A" />
        <ReferenceChip
          referenceSource="clientId"
          tooltipSource="clientNo"
          source="clientId"
          label="Client"
          reference="clients"
          emptyText="N/A"
          link="show"
          chipSource="name"
        />
        <ReferenceChip
          referenceSource="vendorId"
          tooltipSource=""
          source="vendorId"
          label="Vendor"
          reference="vendors"
          emptyText="N/A"
          link="show"
          chipSource="name"
        />
        <ReferenceChip
          referenceSource="projectId"
          tooltipSource="projectNo"
          source="projectId"
          label="Project"
          reference="projects"
          emptyText="N/A"
          link="show"
          chipSource="title"
        />
        <ReferenceArrayField
          source="countryCode"
          label="Countries"
          reference="countries/list"
          sortable={false}
        >
          <SingleFieldList linkType={false}>
            <ChipField source="name" size="small" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceChip
          referenceSource={'serviceTypeId'}
          tooltipSource="name"
          source="serviceTypeId"
          chipSource="key"
          label={'Type'}
          reference="serviceTypes/list"
          emptyText={'N/A'}
          removeDoubleLabel
          link={false}
        />
        <ReferenceChip
          referenceSource={'serviceCategoryId'}
          tooltipSource="name"
          source="serviceCategoryId"
          chipSource="key"
          label={'Category'}
          reference="serviceCategories/list"
          emptyText={'N/A'}
          removeDoubleLabel
          link={false}
        />
        <ReferenceChip
          referenceSource={'accessTechnologyId'}
          tooltipSource="name"
          source="accessTechnologyId"
          chipSource="key"
          label={'Technology'}
          reference="accessTechnologies/list"
          emptyText={'N/A'}
          removeDoubleLabel
          link={false}
        />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </ConfigurableLabelledDatagrid>
    </List>
  )
}

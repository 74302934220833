import React from 'react'
import { CurrencyField } from '../../atoms/CurrencyField/CurrencyField.component'
import { TextPreSuffixField } from '../../atoms/TextPreSuffixField'
import { MarginMetric } from '../../molecules/MarginMetric'
import { useStyles } from './ProductInformationGrid.styles'
import {
  BooleanField,
  DateField,
  SimpleShowLayout,
  TextField,
  WrapperField,
} from 'react-admin'
import { ReferenceChip } from '../ReferenceChip'
import { ReferenceChipList } from '../ReferenceChipList'

/**
 * Grid layout containing all of the products information.
 * From purchase and sales prices to margins and VAT.
 * @returns ReactElement
 * @example
 * return <ProductInformationGrid />
 * @see https://marmelab.com/react-admin/Fields.html
 * @see https://marmelab.com/react-admin/Inputs.html
 * @see https://material-ui.com/system/grid/
 * @see https://material-ui.com/components/typography/#typography
 * @see https://material-ui.com/components/text-fields/#textfield
 */
export const ProductInformationGrid: React.FC = () => (
  <SimpleShowLayout sx={useStyles}>
    <TextField
      source="priceStatusNrc"
      label="Price status NRC"
      emptyText="N/A"
    />
    <TextField
      source="priceStatusMrc"
      label="Price status MRC"
      emptyText="N/A"
    />
    <TextField source="purchaseCurrency" emptyText="N/A" />
    <CurrencyField
      source="purchaseNrc"
      currency={'purchaseCurrency'}
      label="Purchase NRC"
    />
    <CurrencyField
      source="purchaseMrc"
      currency={'purchaseCurrency'}
      label="Purchase MRC"
    />
    <CurrencyField
      source="valueNrc"
      currency={'valueCurrency'}
      label="Value NRC"
    />
    <CurrencyField
      source="valueMrc"
      currency={'valueCurrency'}
      label="Value MRC"
    />
    <CurrencyField
      source="priceCalculation.salesNrc"
      currency={'priceCalculation.salesCurrency'}
      label="Sales NRC"
    />
    <CurrencyField
      source="priceCalculation.salesMrc"
      currency={'priceCalculation.salesCurrency'}
      label="Sales MRC"
    />
    <ReferenceChip
      label="Margin book"
      source="priceCalculation.marginBookId"
      tooltipSource="name"
      chipSource="name"
      referenceSource="priceCalculation.marginBookId"
      reference="marginBooks"
      emptyText="N/A"
      link={'show'}
      removeDoubleLabel
    />
    <WrapperField label="Margin NRC">
      <MarginMetric
        relativeSource="priceCalculation.marginRelativeNrc"
        absoluteSource="priceCalculation.marginAbsoluteNrc"
        currency={'priceCalculation.valueCurrency'}
      />
    </WrapperField>
    <WrapperField label="Margin MRC" sortBy="salesMarginMrc">
      <MarginMetric
        relativeSource="priceCalculation.marginRelativeMrc"
        absoluteSource="priceCalculation.marginAbsoluteMrc"
        currency={'priceCalculation.valueCurrency'}
      />
    </WrapperField>
    <CurrencyField
      source="priceCalculation.salesValueNrc"
      currency={'valueCurrency'}
      label="Sales value NRC"
    />
    <CurrencyField
      source="priceCalculation.salesValueMrc"
      currency={'valueCurrency'}
      label="Sales value MRC"
    />
    <TextPreSuffixField
      source="contractTerms.renewalPeriod"
      label={'Renewal period'}
      suffix={' months'}
    />
    <TextPreSuffixField
      source="contractTerms.cancellationPeriod"
      label={'Cancellation period'}
      suffix={' months'}
    />
    <TextPreSuffixField
      source="contractTerms.meanTimeToRepair"
      label="MTTR"
      suffix={' hours'}
    />
    <TextPreSuffixField
      source="contractTerms.leadTime"
      label="Lead time"
      suffix={' weeks'}
    />
    <TextPreSuffixField
      source="contractTerms.serviceUptime"
      label="Service uptime"
      suffix={'%'}
    />
    <TextField source="contractTerms.supportLevel" emptyText={'N/A'} />
    <TextField source="contractTerms.serviceMonitoring" emptyText="N/A" />
    <DateField source="updatedAt" emptyText="N/A" showTime />
    <DateField source="createdAt" emptyText="N/A" showTime />
    <ReferenceChip
      label="Price book"
      source="priceBookId"
      tooltipSource="name"
      chipSource="name"
      referenceSource="priceBookId"
      reference="priceBooks"
      emptyText="N/A"
      link={'show'}
      removeDoubleLabel
    />
    <DateField source="activatedAt" emptyText="N/A" showTime />
    <DateField source="deactivatedAt" emptyText="N/A" showTime />
    <ReferenceChipList
      label={'Last mile providers'}
      reference="lastMileProviders/list"
      source="lastMileProviderIds"
      emptyText="N/A"
    />
    <ReferenceChipList
      label={'Internet service providers'}
      reference="internetServiceProviders/list"
      source="internetServiceProviderIds"
      emptyText="N/A"
    />
    <BooleanField source="hasUnknownLmps" emptyText="N/A" />
    <BooleanField source="hasUnknownIsps" emptyText="N/A" />
    <TextField source="notes" emptyText="N/A" />
  </SimpleShowLayout>
)

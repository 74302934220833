import React from 'react'
import {
  Create,
  CreateProps,
  TabbedForm,
  FormTab,
  TextInput,
  BooleanInput,
  required,
  AutocompleteInput,
  ReferenceInput,
  number,
} from 'react-admin'

/**
 * Page to create a client.
 * @property {CreateProps} props
 * @example
 * <ClientCreate />
 * @returns {React.FC<CreateProps>}
 * @see https://marmelab.com/react-admin/Create.html
 */
export const ClientCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label={'general'}>
          <TextInput source="name" validate={required()} fullWidth />
          <BooleanInput source="isActive" defaultValue={true} fullWidth />
          <ReferenceInput
            source={'accountManagerId'}
            reference="users/list"
            filter={{ role: ['admin', 'gnx-admin'] }}
            emptyText={'N/A'}
          >
            <AutocompleteInput
              optionText="name"
              label={'Account Manager'}
              fullWidth
            />
          </ReferenceInput>
          <TextInput source="jiraId" label="Jira organization ID" fullWidth />
          <BooleanInput source="syncJira" defaultValue={true} fullWidth />
        </FormTab>
        <FormTab label={'contact'}>
          <TextInput
            source="address"
            label="Full address"
            validate={required()}
            fullWidth
          />
          <TextInput source="postalCode" validate={required()} fullWidth />
          <ReferenceInput
            source={'countryCode'}
            reference="countries/list"
            emptyText={'N/A'}
          >
            <AutocompleteInput
              optionText="name"
              validate={required()}
              label={'Country'}
              fullWidth
            />
          </ReferenceInput>
          <TextInput source="city" validate={required()} fullWidth />
          <TextInput source="phoneNumber" fullWidth />
          <TextInput source="email" validate={required()} fullWidth />
        </FormTab>
        <FormTab label={'financial'}>
          <ReferenceInput
            source={'marginBookId'}
            reference="marginBooks/list"
            emptyText={'Default'}
          >
            <AutocompleteInput
              optionText="name"
              label={'Margin book'}
              fullWidth
            />
          </ReferenceInput>
          <TextInput source="companyName" validate={required()} fullWidth />
          <TextInput source="companyRegNo" fullWidth />
          <TextInput source="companyVatNo" fullWidth />
          <TextInput source="companyIban" fullWidth />
        </FormTab>
        <FormTab label={'advanced'}>
          <TextInput
            source="jiraId"
            label="Jira organization ID"
            validate={[number()]}
            fullWidth
          />
          <BooleanInput source="syncJira" fullWidth />
        </FormTab>
      </TabbedForm>
    </Create>
  )
}

import React from 'react'
import {
  Filter,
  TextInput,
  ReferenceInput,
  AutocompleteArrayInput,
  SelectInput,
} from 'react-admin'
import { ICoverageZoneFilters } from './CoverageZoneFilters.types'

/**
 * CoverageZoneFilters on the list page.
 * @param {ICoverageZoneFilters} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <CoverageZoneFilters />
 * )
 * @see {@link https://marmelab.com/react-admin/List.html#the-filter-buttonform-combination|Filter Button/Filter Form Combination}
 */
export const CoverageZoneFilters: React.FC<ICoverageZoneFilters> = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="term" alwaysOn />
      <SelectInput
        source="type"
        choices={[
          { id: 'nationwide', name: 'Nationwide' },
          { id: 'area', name: 'Area' },
          { id: 'address', name: 'Address' },
          { id: 'datacenter', name: 'Datacenter' },
          { id: 'api', name: 'API' },
        ]}
        alwaysOn
      />
      <ReferenceInput source="countryCode" reference="countries/list" alwaysOn>
        <AutocompleteArrayInput label={'Country'} optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="vendorId" reference="vendors/list" alwaysOn>
        <AutocompleteArrayInput label={'Vendor'} optionText="name" />
      </ReferenceInput>
    </Filter>
  )
}

import React from 'react'
import AccessTechnologyIcon from '@mui/icons-material/NetworkWifiOutlined'
import { useGetList, FilterList, FilterListItem } from 'react-admin'

/**
 * Filter by the access technology attribute (multi select).
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <AccessTechnologyFilter />
 * )
 * @see {@link https://marmelab.com/react-admin/List.html#the-filterlist-component|FilterList}
 * @see {@link https://marmelab.com/react-admin/List.html#the-filterlistitem-component|FilterListItem}
 */
export const AccessTechnologyFilter: React.FC = () => {
  const { data } = useGetList('accessTechnologies/list')

  if (!data) return null

  /**
   * Helper function that checks whether value is selected
   * @returns {Boolean} Whether the value is inside the filtered list
   * @see {@link https://marmelab.com/react-admin/FilterList.html#cumulative-filters}
   */
  const isSelected = (value: any, filters: any) => {
    const technologies = filters['accessTechnologyId']?.['in'] || []
    return technologies.includes(value.accessTechnologyId)
  }

  /**
   * Helper function that toggles the filter selection
   * @returns {any} New filter list
   * @see {@link https://marmelab.com/react-admin/FilterList.html#cumulative-filters}
   */
  const toggleFilter = (value: any, filters: any) => {
    const technologies = filters['accessTechnologyId']?.['in'] || []
    return {
      ...filters,
      accessTechnologyId: {
        ...filters?.accessTechnologyId,
        in: technologies.includes(value.accessTechnologyId)
          ? technologies.filter((v: string) => v !== value.accessTechnologyId)
          : [...technologies, value.accessTechnologyId],
      },
    }
  }

  return (
    <FilterList label="Technology" icon={<AccessTechnologyIcon />}>
      {data.map((record) => (
        <FilterListItem
          key={record.id}
          label={record.name}
          value={{ accessTechnologyId: record.id }}
          isSelected={isSelected}
          toggleFilter={toggleFilter}
        />
      ))}
    </FilterList>
  )
}

import React from 'react'
import { ListBase, ListButton } from 'react-admin'
import { Card, CardHeader, CardContent } from '@mui/material'
import { Segment } from '../../atoms/Segment'
import { RequestServiceDatagrid } from '../../molecules/RequestServiceDatagrid'

/** This component displays the most recently updated request services. */
export const RequestServicesSegment: React.FC = () => {
  return (
    <Segment>
      <Card sx={{ flex: 1 }}>
        <CardHeader
          subheader="Recently Updated Request Services"
          action={
            <ListButton resource="requestServices" label="Request Services" />
          }
        />
        <CardContent>
          <ListBase
            resource="requestServices"
            perPage={5}
            sort={{ field: 'updatedAt', order: 'DESC' }}
            disableSyncWithLocation
          >
            <RequestServiceDatagrid />
          </ListBase>
        </CardContent>
      </Card>
    </Segment>
  )
}

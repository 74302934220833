import React from 'react'
import { DualSelect } from '../../atoms/DualSelect'
import {
  Edit,
  EditProps,
  BooleanInput,
  TextInput,
  required,
  SelectInput,
  FormTab,
  TabbedForm,
} from 'react-admin'

/**
 * Edit Application page.
 * @property {EditProps} props
 * @returns {JSX.Element} ApplicationEdit component
 * @see {@link https://marmelab.com/react-admin/CreateEdit.html|CreateEdit}
 */
export const ApplicationEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit {...props} mutationMode="pessimistic">
      <TabbedForm>
        <FormTab label="general" key="general">
          <TextInput disabled label="Id" source="id" fullWidth />
          <TextInput source="name" validate={required()} fullWidth />
          <BooleanInput source="isActive" defaultValue={true} fullWidth />
          <SelectInput
            source="dataView"
            defaultValue={'client'}
            choices={[
              { id: 'client', name: 'Client (default)' },
              {
                id: 'clientPlusVendor',
                name: 'Client+Vendor (reveals Vendor)',
              },
              { id: 'technical', name: 'Client Technical (hides financials)' },
            ]}
            fullWidth
          />
        </FormTab>
        <FormTab label="clients" key="clients">
          <DualSelect
            label="Clients"
            source="clientIds"
            reference="clients/list"
          />
        </FormTab>
        <FormTab label="permissions" key="permissions">
          <DualSelect
            label="Permissions"
            source="permissionIds"
            reference="permissions/list"
          />
        </FormTab>
        <FormTab label="preferences" key="preferences">
          <BooleanInput
            source="preferences.showLmpsOnSearchResults"
            label={'Show LMPs on search results'}
            defaultValue={false}
          />
          <BooleanInput
            source="preferences.showIspsOnSearchResults"
            label={'Show ISPs on search results'}
            defaultValue={false}
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

import React from 'react'
import {
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  TabbedShowLayoutTabs,
  SimpleShowLayout,
  FunctionField,
  TextInput,
  SelectArrayInput,
  Filter,
} from 'react-admin'
import { MutationsList } from '../../organisms/MutationsList'
import { PageTitle } from '../../atoms/PageTitle'
import ClientContactPages from '../ClientContact'
import RequestPages from '../Request'
import TicketPages from '../Ticket'
import QuotePages from '../Quote'
import DocumentPages from '../Document'
import ClientContractPages from '../ClientContract'
import ProjectPages from '../Project'
import ServiceSitePages from '../ServiceSite'
import ServicePages from '../Service'
import ServiceOrderPages from '../ServiceOrder'
import UserPages from '../User'
import UserGroupPages from '../UserGroup'
import { RelatedResourcesPage, IPageMap } from '../../molecules/RelatedResource'
import { ReferenceText } from '../../molecules/ReferenceText'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { Grid, Divider, useTheme, useMediaQuery } from '@mui/material'
import { SearchFilter } from '../../atoms/SearchFilter'
import { Toolbar } from '../../molecules/Toolbar'
import { ViewInMetabase } from '../../atoms/ViewInMetabase'

/**
 * Page to show a single client
 * @property {ShowProps} props
 * @returns {React.FC<ShowProps>}
 * @example
 * <ClientShow {...props} />
 * @see https://marmelab.com/react-admin/Show.html
 */
export const ClientShow: React.FC<ShowProps> = (props) => {
  const theme = useTheme()

  const shouldShowDivider = useMediaQuery(theme.breakpoints.down('md'))

  const columnsBreakpoints = { xs: 1, sm: 3, md: 5 } as const

  const RowDivider = () => (
    <Grid item {...columnsBreakpoints}>
      {shouldShowDivider && <Divider />}
    </Grid>
  )

  return (
    <Show
      {...props}
      title={<PageTitle prefix="Client" />}
      actions={
        <Toolbar showEdit>
          <ViewInMetabase />
        </Toolbar>
      }
    >
      <TabbedShowLayout
        tabs={
          <TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" />
        }
      >
        <Tab label="general">
          <Grid container columns={columnsBreakpoints}>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <TextField source="name" label="Client Name" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <TextField source="clientNo" label="Client Number" />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <ReferenceChip
                  referenceSource="accountManagerId"
                  tooltipSource="name"
                  source="name"
                  chipSource="name"
                  label={'Account Manager'}
                  reference="users"
                  emptyText={'N/A'}
                  link="show"
                  removeDoubleLabel
                />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <DateField source="createdAt" showTime />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <DateField source="updatedAt" showTime />
              </SimpleShowLayout>
            </Grid>

            <RowDivider />

            <Grid item xs={1}>
              <SimpleShowLayout>
                <TextField
                  label="Legal Company Name"
                  source="companyName"
                  emptyText="N/A"
                />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <TextField
                  label="Legal Company Address"
                  source="companyAddress"
                  emptyText="N/A"
                />
              </SimpleShowLayout>
            </Grid>

            <Grid item xs={1}>
              <SimpleShowLayout>
                <TextField
                  source="companyRegNo"
                  label="Company Reg No"
                  emptyText="N/A"
                />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <TextField
                  source="companyVatNo"
                  label="Company VAT No"
                  emptyText="N/A"
                />
              </SimpleShowLayout>
            </Grid>
            <Grid item xs={1}>
              <SimpleShowLayout>
                <TextField source="companyIban" label="IBAN" emptyText="N/A" />
              </SimpleShowLayout>
            </Grid>

            <RowDivider />
          </Grid>
        </Tab>
        <Tab label="contact">
          <TextField source="address" label="Full address" fullWidth />
          <TextField source="postalCode" fullWidth />
          <ReferenceText
            source="countryCode"
            reference="countries/list"
            emptyText={'N/A'}
            link={false}
          />
          <TextField source="city" fullWidth />
          <TextField source="phoneNumber" fullWidth />
          <TextField source="email" fullWidth />
        </Tab>
        <Tab label="financial">
          <ReferenceChip
            referenceSource="marginBookId"
            tooltipSource="name"
            source="name"
            chipSource="name"
            label={'Margin book'}
            reference="marginBooks"
            emptyText={'Default'}
            link="show"
            removeDoubleLabel
          />
          <TextField source="companyName" fullWidth />
          <TextField source="companyRegNo" fullWidth />
          <TextField source="companyVatNo" fullWidth />
          <TextField source="companyIban" fullWidth />
        </Tab>
        <Tab label="history">
          <MutationsList showActor type="history" />
        </Tab>
        <Tab label="related">
          <FunctionField
            render={(
              client: Parameters<typeof getRelatedResourceNameToComponentMap>[0]
            ) => (
              <RelatedResourcesPage
                resource="client"
                pages={getRelatedResourceNameToComponentMap(client)}
              />
            )}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

/**
 * Returns a map of related resources to their respective components.
 * Used by the RelatedResourcesPage component.
 */
function getRelatedResourceNameToComponentMap(client: {
  id: string
  userIds: string[]
  userGroupIds: string[]
}): IPageMap {
  const UsersList = UserPages.list
  const UserGroupsList = UserGroupPages.list
  const ContactsList = ClientContactPages.list
  const RequestsList = RequestPages.list
  const TicketsList = TicketPages.list
  const QuotesList = QuotePages.list
  const ProjectsList = ProjectPages.list
  const DocumentsList = DocumentPages.list
  const ClientContractsList = ClientContractPages.list
  const ServicesList = ServicePages.list
  const ServiceOrdersList = ServiceOrderPages.list
  const ServiceSitesList = ServiceSitePages.list

  return {
    Users: (
      <UsersList
        children={undefined}
        resource="users"
        filter={{ id: client.userIds }}
      />
    ),
    'User Groups': (
      <UserGroupsList
        children={undefined}
        resource="userGroups"
        filter={{ id: client.userGroupIds }}
      />
    ),
    Contacts: (
      <ContactsList
        children={undefined}
        resource="clientContacts"
        filter={{ clientId: client.id }}
      />
    ),
    Requests: (
      <RequestsList
        children={undefined}
        resource="requests"
        filter={{ clientId: client.id }}
        filters={
          <Filter>
            <TextInput label="Search" source="term" alwaysOn />
            <SelectArrayInput
              label="State"
              source="state"
              choices={[
                { id: 'draft', name: 'Draft' },
                { id: 'in-progress', name: 'In Progress' },
                { id: 'done', name: 'Done' },
                { id: 'archived', name: 'Archived' },
              ]}
              alwaysOn
            />
          </Filter>
        }
      />
    ),
    Tickets: (
      <TicketsList
        children={undefined}
        resource="tickets"
        filter={{ clientId: client.id }}
        filters={
          <Filter>
            <TextInput label="Search" source="term" alwaysOn />
            <SelectArrayInput
              source="state"
              alwaysOn
              choices={[
                { id: 'pending', name: 'Pending' },
                { id: 'in-progress', name: 'In Progress' },
                { id: 'done', name: 'Done' },
              ]}
              fullWidth
            />
          </Filter>
        }
      />
    ),
    Quotes: (
      <QuotesList
        children={undefined}
        resource="quotes"
        filter={{ clientId: client.id }}
      />
    ),
    Projects: (
      <ProjectsList
        children={undefined}
        resource="projects"
        filter={{ clientId: client.id }}
        filters={<SearchFilter />}
      />
    ),
    Documents: (
      <DocumentsList
        children={undefined}
        resource="documents"
        filter={{ clientId: client.id }}
      />
    ),
    Contracts: (
      <ClientContractsList
        children={undefined}
        resource="clientContracts"
        filter={{ clientId: client.id }}
        filters={<SearchFilter />}
      />
    ),
    Services: (
      <ServicesList
        children={undefined}
        resource="services"
        filter={{ clientId: client.id }}
        filters={<SearchFilter />}
        actions={undefined}
      />
    ),
    'Service Orders': (
      <ServiceOrdersList
        children={undefined}
        resource="serviceOrders"
        filter={{ clientId: client.id }}
      />
    ),
    'Service Sites': (
      <ServiceSitesList
        children={undefined}
        resource="serviceSites"
        filter={{ clientId: client.id }}
        filters={<SearchFilter />}
      />
    ),
  }
}

import React from 'react'
import { useFormContext } from 'react-hook-form'
import {
  FormDataConsumer,
  NumberInput,
  SelectInput,
  TextInput,
  minValue,
  maxValue,
  BooleanInput,
  required,
} from 'react-admin'
import get from 'lodash/get'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { Box } from '@mui/material'
import { prefixSource } from '../../particles/utils'
import { DateInput } from '../../atoms/DateInput'

/**
 * Form to create and edit client contracts.
 * @param {string} props.reference - The reference of the client contract.
 * @param {string} props.sourcePrefix - The prefix of the source.
 * @returns {React.FC} The component.
 * @example
 * import { ClientContractForm } from './ClientContractForm.component.tsx'
 * <ClientContractForm />
 * @see {@link https://marmelab.com/react-admin/CreateEdit.html|CreateEdit}
 */
export const ClientContractForm: React.FC<{
  reference?: string
  sourcePrefix?: string
}> = (props) => {
  const form = useFormContext()
  const { clientContract, vendorContract, clientId, ...otherFormData } =
    form.getValues()

  const updateSalesMarginNrc = async () => {
    if (vendorContract?.purchaseNrc && clientContract?.salesNrc) {
      if (!clientContract.salesMarginNrc) {
        const calcValue = clientContract.salesNrc - vendorContract.purchaseNrc
        await form.setValue(
          prefixSource('salesMarginNrc', props.sourcePrefix),
          calcValue
        )
      }
    }
  }

  const updateSalesMarginMrc = async () => {
    if (vendorContract?.purchaseMrc && clientContract?.salesMrc) {
      if (!clientContract.salesMarginMrc) {
        const calcValue = clientContract.salesMrc - vendorContract.purchaseMrc
        await form.setValue(
          prefixSource('salesMarginMrc', props.sourcePrefix),
          calcValue
        )
      }
    }
  }

  return (
    <FormDataConsumer>
      {({ formData }) => (
        <Box display={{ width: '100%' }}>
          {!props.reference && (
            <Box display={{ xs: 'block', sm: 'flex' }} sx={{ p: 0.5 }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <ReferenceAutocomplete
                  label="Client"
                  source={prefixSource('clientId', props.sourcePrefix)}
                  reference="clients/list"
                  disableSyncWithLocation
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <FormDataConsumer>
                  {({ formData }) => (
                    <ReferenceAutocomplete
                      source={prefixSource('serviceId', props.sourcePrefix)}
                      reference="services/list"
                      filter={{
                        clientId: get(
                          formData,
                          prefixSource('clientId', props.sourcePrefix)
                        ),
                      }}
                      disableSyncWithLocation
                      label="Service"
                    />
                  )}
                </FormDataConsumer>
              </Box>
            </Box>
          )}
          <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput
                source={prefixSource('contractNo', props.sourcePrefix)}
                label="Contract no. (CSO)"
                fullWidth
              />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput
                source={prefixSource('reference', props.sourcePrefix)}
                label="Reference"
                fullWidth
              />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <SelectInput
                source={prefixSource('state', props.sourcePrefix)}
                defaultValue="active"
                validate={required()}
                choices={[
                  { id: 'draft', name: 'Draft' },
                  { id: 'confirmed', name: 'Confirmed' },
                  { id: 'active', name: 'Active' },
                  { id: 'cancelled', name: 'Cancelled' },
                  { id: 'ended', name: 'Ended' },
                  { id: 'replaced', name: 'Replaced' },
                ]}
                fullWidth
              />
            </Box>
          </Box>
          <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <FormDataConsumer>
                {() => (
                  <TextInput
                    source={prefixSource('description', props.sourcePrefix)}
                    label="Contract description"
                    defaultValue={otherFormData?.name}
                    validate={[required()]}
                    fullWidth
                  />
                )}
              </FormDataConsumer>
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <FormDataConsumer>
                {() => (
                  <ReferenceAutocomplete
                    label="Related quote"
                    source={prefixSource('quoteId', props.sourcePrefix)}
                    reference="quotes/list"
                    filter={{ clientId: clientId }}
                    disableSyncWithLocation
                  />
                )}
              </FormDataConsumer>
            </Box>
          </Box>
          <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <FormDataConsumer>
                {() => (
                  <DateInput
                    source={prefixSource('periodStart', props.sourcePrefix)}
                    defaultValue={formData.vendorContract?.periodStart || null}
                    label="Contract start date"
                    fullWidth
                  />
                )}
              </FormDataConsumer>
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <FormDataConsumer>
                {({ formData }) => (
                  <DateInput
                    source={prefixSource('periodEnd', props.sourcePrefix)}
                    defaultValue={formData.vendorContract?.periodEnd || null}
                    label="Contract end date"
                    fullWidth
                  />
                )}
              </FormDataConsumer>
            </Box>
          </Box>
          <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <FormDataConsumer>
                {({ formData }) => (
                  <NumberInput
                    source={prefixSource('contractPeriod', props.sourcePrefix)}
                    label="Contract term (months)"
                    defaultValue={formData.vendorContract?.contractPeriod}
                    fullWidth
                    validate={[required()]}
                  />
                )}
              </FormDataConsumer>
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <NumberInput
                source={prefixSource('cancellationPeriod', props.sourcePrefix)}
                defaultValue={formData.vendorContract?.cancellationPeriod}
                label="Cancellation term (days in advance)"
                fullWidth
              />
            </Box>
          </Box>
          <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <NumberInput
                source={prefixSource('billingCycle', props.sourcePrefix)}
                defaultValue={formData.vendorContract?.billingCycle || 1}
                label="Billing cycle (months)"
                fullWidth
              />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <NumberInput
                source={prefixSource('renewalPeriod', props.sourcePrefix)}
                defaultValue={formData.vendorContract?.renewalPeriod || 30}
                label="Renewal term (days in advance)"
                fullWidth
              />
            </Box>
          </Box>
          <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <ReferenceAutocomplete
                required
                label="Sales currency"
                source={prefixSource('salesCurrency', props.sourcePrefix)}
                defaultValue={formData.vendorContract?.purchaseCurrency}
                reference="currencies/list"
                disableSyncWithLocation
              />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <NumberInput
                source={prefixSource('salesNrc', props.sourcePrefix)}
                label="Sales NRC"
                fullWidth
                validate={[required()]}
                onBlur={updateSalesMarginNrc}
              />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <NumberInput
                source={prefixSource('salesMrc', props.sourcePrefix)}
                label="Sales MRC"
                fullWidth
                validate={[required()]}
                onBlur={updateSalesMarginMrc}
              />
            </Box>
          </Box>
          <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <NumberInput
                source={prefixSource('leadTime', props.sourcePrefix)}
                label="Lead time (weeks)"
                defaultValue={
                  formData.vendorContract?.leadTime
                    ? formData.vendorContract?.leadTime + 1
                    : null
                }
                validate={[minValue(0), maxValue(128)]}
                fullWidth
              />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <SelectInput
                source={prefixSource('serviceUptime', props.sourcePrefix)}
                label="Uptime SLA (percent)"
                defaultValue={formData.vendorContract?.serviceUptime}
                choices={[
                  { id: 99, name: '99.0%' },
                  { id: 99.5, name: '99.5%' },
                  { id: 99.8, name: '99.8%' },
                  { id: 99.9, name: '99.9%' },
                  { id: 99.95, name: '99.95%' },
                  { id: 99.99, name: '99.99%' },
                  { id: null, name: 'Best-effort' },
                ]}
                fullWidth
              />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <SelectInput
                source={prefixSource('meanTimeToRepair', props.sourcePrefix)}
                defaultValue={formData.vendorContract?.meanTimeToRepair}
                label="MTTR"
                choices={[
                  { id: 4, name: '4 hours' },
                  { id: 8, name: '8 hours' },
                  { id: 12, name: '12 hours' },
                  { id: 24, name: '24 hours (NBD)' },
                  { id: null, name: 'Best-effort' },
                ]}
                fullWidth
              />
            </Box>
          </Box>
          <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <SelectInput
                source={prefixSource('supportLevel', props.sourcePrefix)}
                defaultValue={formData.vendorContract?.supportLevel}
                label="Support level"
                choices={[
                  { id: '24x7x365', name: '24 x 7 x 365' },
                  { id: '5x8', name: '5 x 8' },
                  { id: 'other', name: 'Other' },
                ]}
                fullWidth
              />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <SelectInput
                source={prefixSource('serviceMonitoring', props.sourcePrefix)}
                defaultValue={formData.vendorContract?.serviceMonitoring}
                label="Service monitoring"
                choices={[
                  { id: 'standard', name: 'Standard 24 x 7 x 365' },
                  { id: 'pro-active', name: 'Pro-active 24 x 7 x 365' },
                  { id: 'none', name: 'None' },
                ]}
                fullWidth
              />
            </Box>
          </Box>
          <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <BooleanInput
                source={prefixSource('isSigned', props.sourcePrefix)}
                label="Contract signed"
                fullWidth
              />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <BooleanInput
                source={prefixSource('isCustom', props.sourcePrefix)}
                label="Custom offer"
                fullWidth
              />
            </Box>
          </Box>
          <Box display={{ sm: 'block', md: 'flex' }} sx={{ p: 0.5 }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput
                source={prefixSource('extraTerms', props.sourcePrefix)}
                label="Additional contract terms"
                multiline
                fullWidth
              />
            </Box>
          </Box>
        </Box>
      )}
    </FormDataConsumer>
  )
}

import React from 'react'
import { Datagrid, TextField, EditButton, ShowButton } from 'react-admin'
import { ContactDetails } from './QuoteOrderDetailsAccordion.component'

export const ContactDetail: React.FC<{
  contact?: ContactDetails
  editable?: boolean
}> = ({ contact, editable }) => {
  return (
    <Datagrid bulkActionButtons={false}>
      <TextField source="name" record={contact} />
      <TextField source="emailAddress" record={contact} />
      <TextField source="phoneNumber" record={contact} />
      {editable !== false ? (
        <EditButton resource={`clientContacts`} record={contact} />
      ) : (
        <ShowButton resource={`clientContacts`} record={contact} />
      )}
    </Datagrid>
  )
}

import {
  Box,
  Typography,
  Chip,
  Paper,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Stack,
} from '@mui/material'
import { useState, useEffect } from 'react'
import {
  useRecordContext,
  useDataProvider,
  useNotify,
  useGetIdentity,
} from 'react-admin'
import { useWatch, useFormContext } from 'react-hook-form'

const UpdateEmailPreview: React.FC = () => {
  const record = useRecordContext()
  const dataProvider = useDataProvider()
  const [loading, setLoading] = useState(false)
  const [previewData, setPreviewData] = useState<{
    content: string
    contacts: { id: string; name: string; role: string; emailAddress: string }[]
  }>()
  const [availableContacts, setAvailableContacts] = useState<
    { id: string; name: string; role: string; emailAddress: string }[]
  >(record.clientContacts ?? [])
  const { setValue, getValues } = useFormContext()
  const { identity } = useGetIdentity()

  const values = useWatch({
    name: [
      'sendImmediate',
      'estimatedDeliveryDate',
      'provisioningUpdateNotes',
      'provisioningStatus',
      'ragStatus',
      'isOnHold',
      'contactIds',
    ],
  })

  const notify = useNotify()

  useEffect(() => {
    if (record.clientContacts) {
      setAvailableContacts(record.clientContacts)
    }
  }, [record.clientContacts])

  useEffect(
    function preSelectContactsToReceiveUpdate() {
      const selectedContacts: string[] | undefined = getValues('contactIds')
      const hasNoSelectedContacts =
        !selectedContacts || selectedContacts.length === 0

      const contactsInPreview = previewData?.contacts
      const hasContactsInPreview =
        contactsInPreview && contactsInPreview?.length !== 0

      if (hasNoSelectedContacts && hasContactsInPreview) {
        const preSelectedContacts = previewData.contacts.map(
          (contact) => contact.id
        )

        setValue('contactIds', preSelectedContacts)
      }
    },
    [getValues, previewData?.contacts, setValue]
  )

  useEffect(() => {
    if (!values) return
    const updatePreview = async () => {
      const parsedPreviewData = {
        sendImmediate: values[0],
        estimatedDeliveryDate: values[1],
        notes: values[2],
        provisioningStatus: values[3],
        ragStatus: values[4],
        isOnHold: values[5],
        contactIds: values[6],
      }
      try {
        setLoading(true)
        const preview = await dataProvider.customRequest(
          `services`,
          `${record.id}/updates/preview`,
          { data: parsedPreviewData, method: 'POST' }
        )
        setPreviewData(preview.json)
        setLoading(false)
      } catch (error: any) {
        setLoading(false)
        notify(error.message || error, { type: 'warning' })
      }
    }
    updatePreview()
  }, [values, dataProvider, notify, record.id])

  const handleContactToggle = (contactId: string) => {
    const currentContactIds: string[] = values[6] || []
    const newContactIds = currentContactIds.includes(contactId)
      ? currentContactIds.filter((id) => id !== contactId)
      : [...currentContactIds, contactId]
    setValue('contactIds', newContactIds)
  }

  if (loading) {
    return (
      <Box
        width={'100%'}
        display={'flex'}
        justifyContent={'center'}
        alignContent={'center'}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box sx={{ mb: 3 }}>
      {availableContacts.length === 0 ? (
        <Typography p={1} variant="body2" sx={{ fontWeight: 'bold' }}>
          This service does not appear to have any contacts that will receive
          immediate updates. Add them first under Service - Edit before saving
          this update.
        </Typography>
      ) : (
        <Box p={1} sx={{ mb: 2 }}>
          <Typography variant="body2">
            Select contacts to receive this update:
          </Typography>
          <Stack direction="column">
            {availableContacts.map((contact) => (
              <FormControlLabel
                key={contact.id}
                control={
                  <Checkbox
                    checked={values[6]?.includes(contact.id)}
                    onChange={() => handleContactToggle(contact.id)}
                  />
                }
                label={`${contact.name} (${contact.role}) <${contact.emailAddress}>`}
              />
            ))}
          </Stack>
        </Box>
      )}
      {previewData?.contacts && previewData.contacts.length > 0 && (
        <>
          <Typography p={1} variant="body2">
            The following is a preview of the update that will be sent out to
            the selected contacts for this service.
          </Typography>
          <Box sx={{ pb: 2 }}>
            <Typography p={1} variant="body2">
              To:{' '}
              {previewData?.contacts?.map((contact: any) => (
                <Chip
                  sx={{ m: 0.25 }}
                  key={contact.id}
                  variant="outlined"
                  size="small"
                  label={`${contact.name} (${contact.role}) <${contact.emailAddress}>`}
                />
              ))}
            </Typography>
            <Typography p={1} variant="body2">
              From:{' '}
              <Chip
                sx={{ m: 0.25 }}
                variant="outlined"
                size="small"
                label="GNX operations"
              />
            </Typography>
            {identity && (
              <Typography p={1} variant="body2">
                Reply-To:{' '}
                <Chip
                  sx={{ m: 0.25 }}
                  variant="outlined"
                  size="small"
                  label={identity.fullName}
                />
              </Typography>
            )}
          </Box>
          <Paper sx={{ p: 2, mb: 2 }}>
            <div
              dangerouslySetInnerHTML={{
                __html: previewData?.content ?? '',
              }}
            />
          </Paper>
          <Typography variant="caption" sx={{ p: 1 }}>
            Note that opening and closing words will be added automatically and
            should not be included in the notes. The preview above is of just
            the part that describes the update.
          </Typography>
        </>
      )}
    </Box>
  )
}

export default UpdateEmailPreview

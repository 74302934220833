import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import { SimpleShowLayout, TextField, FunctionField } from 'react-admin'
import InfoIcon from '@mui/icons-material/Info'
import ExpandAccordionIcon from '@mui/icons-material/ExpandMore'
import { Map } from '../../atoms/Map'
import DoneIcon from '@mui/icons-material/CheckBoxOutlined'
import ToDoIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { useStyles } from './SiteDetailsAccordion.styles'

/** Expects `RequestSite` as the RecordContext. */
export const SiteDetailsAccordion: React.FC = () => (
  <Accordion defaultExpanded disableGutters sx={{ width: '100%' }}>
    <AccordionSummary expandIcon={<ExpandAccordionIcon color="info" />}>
      <Grid
        container
        alignItems="center"
        gap={0.5}
        justifyContent="space-between"
      >
        <Grid item>
          <FunctionField
            render={(record: {
              state: string
              name: string
              address: string
            }) => (
              <Typography
                variant="h6"
                alignItems="center"
                display="flex"
                gap={1}
              >
                {record.state === 'confirmed' ? (
                  <DoneIcon sx={{ mr: 1 }} />
                ) : (
                  <ToDoIcon sx={{ mr: 1 }} />
                )}
                Request: Site Details
              </Typography>
            )}
          />
        </Grid>
      </Grid>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container>
        <Grid item lg={7}>
          <SimpleShowLayout sx={useStyles}>
            <TextField source="address" label="Full address" emptyText="N/A" />
            <TextField source="name" label="Name" emptyText="N/A" />
            <TextField source="reference" label="Reference" emptyText="N/A" />
            <TextField source="country.name" label="Country" emptyText="N/A" />
            <TextField source="city" label="City" emptyText="N/A" />
            <TextField
              source="postalCode"
              label="Postal code"
              emptyText="N/A"
            />
            <TextField
              source="streetName"
              label="Street name"
              emptyText="N/A"
            />
            <TextField
              source="streetNumberSuffix"
              label="Street number suffix"
              emptyText="N/A"
            />
            <TextField
              source="streetNumberRoom"
              label="Street number room"
              emptyText="N/A"
            />
            <TextField
              source="geometry.coordinates[1]"
              label="Latitude"
              emptyText="N/A"
            />
            <TextField
              source="geometry.coordinates[0]"
              label="Longitude"
              emptyText="N/A"
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item lg={5} sx={{ minHeight: 280 }}>
          <FunctionField
            render={(site: { geometry: object }) =>
              site.geometry ? (
                <Map />
              ) : (
                <Box
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                  height="100%"
                  bgcolor="#dddddd"
                  borderRadius={2}
                  textTransform="uppercase"
                >
                  <Stack gap={1} alignItems="center">
                    <InfoIcon color="disabled" fontSize="large" />
                    <Typography
                      variant="body2"
                      color="GrayText"
                      fontSize={12}
                      fontWeight={600}
                    >
                      Address Incomplete
                    </Typography>
                  </Stack>
                </Box>
              )
            }
          />
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>
)

import React from 'react'
import {
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  BooleanInput,
  FormDataConsumer,
} from 'react-admin'

import { PageTitle } from '../../atoms/PageTitle'
import { MutationsList } from '../../organisms/MutationsList'
import { TypeStateField } from '../../atoms/TypeStateField'
import { DownloadFileButton } from '../../atoms/DownloadFileButton'
import { Toolbar } from '../../molecules/Toolbar'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { ReferenceText } from '../../molecules/ReferenceText'
import { IPageMap, RelatedResourcesPage } from '../../molecules/RelatedResource'
import { PriceBookPreviewer } from '../../templates/PriceBookPreviewer'
import { ProductsList } from '../../organisms/ProductsList'
import { ProcessOperationButton } from '../../atoms/ProcessOperationButton'
import { PriceBookMapper } from '../../templates/PriceBookMapper'

/**
 * List of related pages to show in the "Related" tab
 */
const relatedPages: IPageMap = {
  Products: (
    <ProductsList
      target="priceBookId"
      omit={['vendorId', 'name']}
      filter={{ isActive: 'both' }}
    />
  ),
}

/**
 * Page to show a price book
 * @param props
 * @prop {ShowProps} props
 * @returns {JSX.Element}
 * @example
 * <PriceBookShow {...props}/>
 * @see https://marmelab.com/react-admin/Show.html
 * @see https://marmelab.com/react-admin/Fields.html
 */
export const PriceBookShow: React.FC<ShowProps> = (props) => {
  return (
    <Show
      {...props}
      title={<PageTitle prefix="Price book" />}
      actions={
        <Toolbar showEdit>
          <DownloadFileButton resource="priceBooks" />
          <ProcessOperationButton
            operation="approve"
            label="Approve"
            title="Approve price book?"
            resource="priceBooks"
            enabledInStates={['pending', 'draft']}
            hideIfDisabled
          >
            <FormDataConsumer>
              {({ formData }) => (
                <>
                  <BooleanInput
                    label="Also apply price book"
                    source="alsoApply"
                    defaultValue={true}
                  />
                  {formData?.alsoApply !== false || formData?.alsoApply ? (
                    <span>
                      Applying the price book will trigger an update of the
                      product index and write to the database. Any existing
                      products for this vendor+country combination will be
                      replaced. Do you want to continue?
                    </span>
                  ) : null}
                </>
              )}
            </FormDataConsumer>
          </ProcessOperationButton>
          <ProcessOperationButton
            operation="apply"
            label="Apply"
            resource="priceBooks"
            enabledInStates={['approved']}
            hideIfDisabled
          />
        </Toolbar>
      }
    >
      <TabbedShowLayout {...props}>
        <Tab label="general">
          <TextField source="name" />
          <TypeStateField source="state" />
          <ReferenceChip
            referenceSource="vendorId"
            tooltipSource="name"
            source="vendorId"
            label="Vendor"
            reference="vendors"
            emptyText={'N/A'}
            chipSource="name"
          />
          <ReferenceText
            source="currencyCode"
            reference="currencies/list"
            emptyText={'N/A'}
            label="Currency"
            link={false}
          />
          <ReferenceText
            source="countryCode"
            reference="countries/list"
            emptyText={'N/A'}
            label="Country"
            link={false}
          />
          <DateField source="appliedAt" showTime />
          <DateField source="updatedAt" showTime />
          <DateField source="createdAt" showTime />
        </Tab>
        <Tab label={'preview'}>
          <PriceBookPreviewer />
        </Tab>
        <Tab label="mapping">
          <PriceBookMapper />
        </Tab>
        <Tab label={'notes'}>
          <TextField source="notes" emptyText={'N/A'} fullWidth />
        </Tab>
        <Tab label="related">
          <RelatedResourcesPage resource="priceBook" pages={relatedPages} />
        </Tab>
        <Tab label={'history'}>
          <MutationsList showActor type="history" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

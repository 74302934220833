import React from 'react'
import {
  List,
  ListProps,
  TextField,
  BooleanField,
  DateField,
  BooleanInput,
  Filter,
  TextInput,
  AutocompleteInput,
  ReferenceInput,
} from 'react-admin'
import { ReferenceText } from '../../molecules/ReferenceText'
import { LabelledDatagrid } from '../../atoms/LabelledDatagrid'
import { ReferenceChip } from '../../molecules/ReferenceChip'

/**
 * Page to list clients
 * @property {ListProps} props
 * @example
 * <ClientList />
 * @returns {React.FC<ListProps>}
 * @see https://marmelab.com/react-admin/List.html
 */
export const ClientList: React.FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      empty={false}
      perPage={25}
      filters={
        <Filter>
          <TextInput label="Search" source="term" alwaysOn />
          <BooleanInput source="isActive" label="Only show active" />
          <ReferenceInput
            source={'marginBookId'}
            reference="marginBooks/list"
            emptyText={'Default'}
          >
            <AutocompleteInput
              optionText="name"
              label={'Margin book'}
              fullWidth
            />
          </ReferenceInput>
          <ReferenceInput
            source={'accountManagerId'}
            reference="users/list"
            filter={{ role: ['admin', 'gnx-admin'] }}
            emptyText={'N/A'}
          >
            <AutocompleteInput
              optionText="name"
              label={'Account Manager'}
              fullWidth
            />
          </ReferenceInput>
          <ReferenceInput
            source={'countryCode'}
            reference="countries/list"
            emptyText={'N/A'}
          >
            <AutocompleteInput optionText="name" label={'Country'} fullWidth />
          </ReferenceInput>
        </Filter>
      }
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <LabelledDatagrid rowClick="show">
        <TextField source="clientNo" fullWidth />
        <TextField source="name" fullWidth />
        <ReferenceText
          source="countryCode"
          reference="countries/list"
          emptyText={'N/A'}
          link={false}
        />
        <ReferenceChip
          referenceSource="accountManagerId"
          tooltipSource="name"
          source="name"
          chipSource="name"
          label={'Account Manager'}
          reference="users"
          emptyText={'N/A'}
          link="show"
          removeDoubleLabel
        />
        <BooleanField source="isActive" fullWidth />
        <DateField source="updatedAt" showTime />
      </LabelledDatagrid>
    </List>
  )
}

import React from 'react'
import { DualSelect } from '../../atoms/DualSelect'
import {
  Create,
  CreateProps,
  BooleanInput,
  TextInput,
  required,
  SelectInput,
  TabbedForm,
  FormTab,
} from 'react-admin'

/**
 * Create Application page.
 * @property {CreateProps} props
 * @returns {JSX.Element}
 * @see {@link https://marmelab.com/react-admin/CreateEdit.html|CreateEdit}
 */
export const ApplicationCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label="general" key="general">
          <TextInput source="name" validate={required()} fullWidth />
          <BooleanInput source="isActive" defaultValue={true} fullWidth />
          <SelectInput
            source="dataView"
            defaultValue={'client'}
            choices={[
              { id: 'client', name: 'Client (default)' },
              {
                id: 'clientPlusVendor',
                name: 'Client+Vendor (reveals Vendor)',
              },
              { id: 'technical', name: 'Client Technical (hides financials)' },
            ]}
            fullWidth
          />
        </FormTab>
        <FormTab label="clients" key="clients">
          <DualSelect
            label="Clients"
            source="clientIds"
            reference="clients/list"
          />
        </FormTab>
        <FormTab label="permissions" key="permissions">
          <DualSelect
            label="Permissions"
            source="permissionIds"
            reference="permissions/list"
          />
        </FormTab>
        <FormTab label="preferences" key="preferences">
          <BooleanInput
            source="preferences.showLmpsOnSearchResults"
            label={'Show LMPs on search results'}
            defaultValue={false}
          />
          <BooleanInput
            source="preferences.showIspsOnSearchResults"
            label={'Show ISPs on search results'}
            defaultValue={false}
          />
        </FormTab>
      </TabbedForm>
    </Create>
  )
}

import React from 'react'
import { IRequestServiceFilters } from './RequestServiceFilters.types'
import {
  Filter,
  TextInput,
  SelectArrayInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
} from 'react-admin'

/**
 * Filters for a list of request services.
 * @property {IMonitorProbeFilters} props The properties of the component.
 * @returns {JSX.Element} The JSX to render.
 * @example
 * <RequestServiceFilters />
 * @see https://marmelab.com/react-admin/List.html#the-filter-button
 * @see https://marmelab.com/react-admin/List.html#the-filter-form
 * @see https://marmelab.com/react-admin/List.html#the-filter-button
 */
export const RequestServiceFilters: React.FC<IRequestServiceFilters> = (
  props
) => {
  return (
    <Filter {...props}>
      <ReferenceInput source="requestId" reference="requests" alwaysOn>
        <AutocompleteInput
          label="Request No"
          optionText="requestNo"
          sx={{ minWidth: 210 }}
        />
      </ReferenceInput>

      <SelectArrayInput
        source="state"
        label="State"
        choices={[
          { id: 'draft', name: 'Draft' },
          { id: 'pending-search', name: 'Pending Search' },
          { id: 'pending-results', name: 'Pending Results' },
          { id: 'pending-request', name: 'Pending Request' },
          { id: 'pending-review', name: 'Pending Review' },
          { id: 'pending-offers', name: 'Pending Offers' },
          { id: 'pending-order', name: 'Pending Order' },
          {
            id: 'pending-order-confirmation',
            name: 'Pending Order Confirmation',
          },
          { id: 'ordered', name: 'Ordered' },
          { id: 'cancelled', name: 'Cancelled' },
          { id: 'archived', name: 'Archived' },
        ]}
        alwaysOn
      />

      <SelectInput
        source="waitingForRole"
        label="Waiting For"
        choices={[
          { id: 'sales', name: 'Sales' },
          { id: 'customer', name: 'Customer' },
          { id: 'procurement', name: 'Procurement' },
          { id: 'order', name: 'Order' },
        ]}
        alwaysOn
      />

      <TextInput label="Term" source="term" alwaysOn />

      <ReferenceArrayInput
        source="assigneeIds"
        reference="users/list"
        filter={{
          role: {
            in: [
              'gnx-procurement',
              'gnx-sales',
              'gnx-admin',
              'admin',
              'sales',
              'procurement',
            ],
          },
        }}
        alwaysOn
      >
        <AutocompleteArrayInput
          label="Assignee(s)"
          optionText="name"
          alwaysOn
        />
      </ReferenceArrayInput>
    </Filter>
  )
}

import { FC } from 'react'
import { Box, Stack } from '@mui/material'
import {
  ReferenceManyField,
  Show,
  SingleFieldList,
  useGetOne,
  useRedirect,
  Button,
} from 'react-admin'
import { useParams } from 'react-router-dom'
import { Breadcrumbs } from '../../atoms/Breadcrumbs'
import { SiteDetailsAccordion } from '../../molecules/SiteDetailsAccordion'
import { ProductSalesReviewAccordion } from '../../atoms/ProductSalesReviewAccordion'
import { RequestDetailsAccordion } from '../../atoms/RequestDetailsAccordion'
import { ProductsTabbedShowLayout } from '../../organisms/ProductsTabbedShowLayout'
import { RequestServiceTabs } from '../../atoms/RequestServiceTabs'
import DownloadIcon from '@mui/icons-material/GetApp'
import { useExportRequestToExcelFunction } from './RequestFlow.hooks'
import { RequestFlowTitle } from './RequestFlowTitle.component'
import { RequestAssignmentAccordion } from '../../molecules/RequestAssignmentAccordion'

export const RequestFlowServiceDetails: FC = () => {
  const { requestId, serviceId } = useParams()
  const redirect = useRedirect()
  const exportRequestToExcel = useExportRequestToExcelFunction()

  const { data: request } = useGetOne(
    'requests',
    { id: requestId },
    { enabled: requestId !== undefined }
  )

  const handleExportButtonClick = async () => {
    if (requestId) await exportRequestToExcel(requestId, { serviceId })
  }

  if (!requestId || !serviceId) {
    redirect('/requests')
    return null
  }

  if (!request) return null

  return (
    <Show
      resource="requestServices"
      id={serviceId}
      component={Box}
      title={<RequestFlowTitle />}
    >
      <Stack gap={1} paddingX={1} paddingY={0}>
        <Stack direction="row" justifyContent="space-between" paddingY={1}>
          <Breadcrumbs />
          <Button
            label="ra.action.export"
            type="button"
            onClick={handleExportButtonClick}
            startIcon={<DownloadIcon />}
          />
        </Stack>
        <Stack gap={1}>
          <RequestServiceTabs
            requestId={requestId}
            serviceId={serviceId}
            requestServices={request?.services}
          />
          <ReferenceManyField
            reference="requestSites"
            source="siteIds"
            target="id"
          >
            <SingleFieldList linkType={false}>
              <SiteDetailsAccordion />
            </SingleFieldList>
          </ReferenceManyField>
          <RequestDetailsAccordion />
          <ProductSalesReviewAccordion />
          <RequestAssignmentAccordion />
          <ProductsTabbedShowLayout
            serviceId={serviceId}
            requestId={requestId}
            clientId={request?.clientId}
            endCustomerId={request?.endCustomerId}
          />
        </Stack>
      </Stack>
    </Show>
  )
}
